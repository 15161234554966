import React from 'react';
import { Alert, Flex, ThemeProvider, Theme, Link } from '@aws-amplify/ui-react';

const theme: Theme = {
  name: 'alert-theme',
  tokens: {
    components: {
      alert: {
        // Default styles
        icon: {
          size: { value: '{fontSizes.xxxl}' },
        },

        heading: {
          fontSize: { value: '{fontSizes.large}' },
          fontWeight: { value: '{fontWeights.normal}' },
        },

        // Variations
        info: {
          color: { value: 'white' },
          backgroundColor: { value: '{colors.blue.80}' },
        },
      },
    },
  },
};

const AlertThemeExample = () => (
  <ThemeProvider theme={theme} colorMode="light">
      <Alert variation="info" heading="Domain Update" isDismissible={true}>
        Looks like you're visiting from supinie.com<br />
        The site is now fully hosted on kaleidos.ai and we will be shutting down access via supinie.com soon.<br />
        You can visit kaleidos.ai <Link href="https://www.kaleidos.ai" textDecoration="underline" color='#FFFFFF'>here</Link>
      </Alert>
  </ThemeProvider>
);

const DomainAlert = () => {
    const currentDomain = window.location.hostname;
    
    if (currentDomain === 'www.supinie.com') {
        return <AlertThemeExample />;
    }

    return null;
}

export default DomainAlert;
