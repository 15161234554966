import React from 'react';
import { Header } from 'semantic-ui-react';
import { SelectField } from '@aws-amplify/ui-react';
import { countries } from './CountryMap';
import { fortnightsArray } from './Fortnights';
import { useLocation } from 'react-router-dom';
import './css/SelectRegionDate.css';
import 'semantic-ui-css/semantic.min.css';


const FortnightDropdown = ({date, setDate, region, setRegion, updateData}) => {
    const location = useLocation();
    let ad_type = "";
    if (location.pathname === '/sp') {
        ad_type = "Sponsored Products";
    } else if (location.pathname === '/sd') {
        ad_type = "Sponsored Display";
    } else if (location.pathname === '/sb') {
        ad_type = "Sponsored Brands";
    }


  const handleCountryClick = (countryCode) => {
    setRegion(countryCode);
    updateData(date, countryCode);
  };

    const handleDateChange = (event) => {
        const newDate = event.target.value;
        setDate(newDate);
        updateData(newDate, region);
    }

    const curr_date = new Date(date);
    curr_date.setDate(curr_date.getDate() - 14);
    const startDate = curr_date.toISOString().slice(0, 10);

  return (
    <>
      <>
        <Header as="h2" className="status-message"
                    style={{
                color: '#f46f22',
                fontSize: '2em',
                fontWeight: 'normal',
              }}
        >
            {ad_type} Performance by Campaign
        </Header>
        <Header as="h2" className="status-message"
                    style={{
                color: '#b9b5b2',
                fontSize: '1.7em',
                fontWeight: 'normal',
              }}
        >
            Current Region: {countries.find((country) => country.code === region)?.emoji || ''}
        </Header>
      </>
    <div className="right-align">
      <div className="button-row">
        {countries.slice(0, -1).map((country) => (
          <button
              style={{ backgroundColor: 'transparent', color: '#f46f22', border: 'none', cursor: 'pointer', fontSize: '2.5em' }}
            key={country.code}
            className="button"
            onClick={() => handleCountryClick(country.code)}
          >
            {country.emoji}
          </button>
        ))}
        <select onChange={handleDateChange} value={date}>
          {fortnightsArray.map((fortnight) => (
            <option key={fortnight.label} value={fortnight.value}>
              {fortnight.label}
            </option>
          ))}
        </select>
      </div>
    </div>
    </>
  );
};

export default FortnightDropdown;


