// CampaignList.js
import React, { useState, useEffect, useCallback } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Table, TableHead, TableBody, TableCell, TableRow, Loader, Link } from '@aws-amplify/ui-react';
// import './css/CampaignList.css';
import '../css/table.css';
import { fetchDifference } from '../Dev';
import { listTodos } from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';


function SpCampaignList({ campaigns, showAll, showFullNames, date }) {
    const [_hoveredCampaignName, setHoveredCampaignName] = useState(null);
    const [sortColumn, setSortColumn] = useState(null);
    const [differences, setDifferences] = useState({});
    const [ noted, setNoted ] = useState([])
    const client = generateClient({ authMode: 'userPool' });

    const fetchNoted = useCallback(async () => {
        const result = await client.graphql({ query: listTodos });
        const IDs = result.data.listTodos.items.map(
            note => note.campaign.slice(-12)
        );
        setNoted(IDs);
    }, [setNoted]);

    useEffect(() => {
        fetchNoted();
    }, [fetchNoted]);

    useEffect(() => {
        const calc_differences = async () => {
            if (displayedCampaigns !== undefined) {
                for (const campaign of campaigns) {
                    const new_difference = await fetchDifference(campaign.campaignId, date);
                    setDifferences((prevDifferences) => ({
                        ...prevDifferences,
                        [campaign.campaignId]: new_difference,
                    }));
                }
            }
        };

        calc_differences();
    }, [campaigns]);

    if (campaigns === undefined) {
        return (
            <div>
                <p>"Please choose a region"</p>
            </div>
        );
    } else if (campaigns === "LOADING") {
            return (
                <div>
                    <Loader
                        size="large"
                    />
                </div>
            );
    }

    const handleSort = (column) => {
        setSortColumn(sortColumn === column ? null : column);
    };

  const displayedCampaigns = campaigns
    .sort((a, b) => {
        if (sortColumn === 'spend') {
            return b.spend - a.spend;
        } else if (sortColumn === 'clicks') {
            return b.clicks - a.clicks;
        } else if (sortColumn === 'costPerClick') {
            return b.costPerClick - a.costPerClick;
        } else if (sortColumn === 'impressions') {
            return b.impressions - a.impressions;
        } else if (sortColumn === 'purchases') {
            return b.purchases1d - a.purchases1d;
        } else if (sortColumn === 'costPerPurchase') {
            const sortValueA =
                a.purchases1d === 0 ? a.spend : a.spend / a.purchases1d;
            const sortValueB =
                b.purchases1d === 0 ? b.spend : b.spend / b.purchases1d;
            return sortValueB - sortValueA;
        } else if (sortColumn === 'sales') {
            return b.sales1d - a.sales1d;
        } else if (sortColumn === 'acos') {
            const sortValueA =
                a.sales1d === 0 ? a.spend : a.spend / a.sales1d;
            const sortValueB =
                b.sales1d === 0 ? b.spend : b.spend / b.sales1d;
            return sortValueB - sortValueA;
        } else if (sortColumn === 'conversion') {
            const conversionA = (a.clicks > 0 ? a.purchases1d / a.clicks : 0);
            const conversionB = (b.clicks > 0 ? b.purchases1d / b.clicks : 0);
            return conversionB - conversionA;
        } else if (sortColumn === 'status') {
            const statusOrder = ['PAUSED', 'ENABLED', 'ARCHIVED'];
            const statusValueA = statusOrder.indexOf(a.campaignStatus);
            const statusValueB = statusOrder.indexOf(b.campaignStatus);
            return statusValueB - statusValueA;
        } else {
            return null;
        }
    });

    const totalImpressions = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.impressions,
        0
    );

    const totalSpend = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.spend,
        0
    );

    const totalSales = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.sales1d,
        0
    );

    const totalClicks = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.clicks,
        0
    );

    const avgCostPerClick = totalClicks > 0 ? (totalSpend / totalClicks).toFixed(2) : 0;

    const totalPurchases = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.purchases1d,
        0
    );

    const totalPurchasedCampaigns = displayedCampaigns.reduce(
        (total, campaign) => total + (campaign.purchases1d > 0 ? 1 : 0),
        0
    );

    const avgCostPerPurchase = (displayedCampaigns.reduce(
        (total, campaign) => total + (campaign.purchases1d > 0 ? campaign.spend / campaign.purchases1d : 0),
        0
    ) / totalPurchasedCampaigns).toFixed(2);

    const avgClickedAcos = (displayedCampaigns.reduce(
        (total, campaign) => total + (campaign.sales1d > 0 ? campaign.spend / campaign.sales1d : 0),
        0
    ) / totalPurchasedCampaigns).toFixed(2) * 100;

    const avgAcos = totalSales > 0 ? (totalSpend / totalSales).toFixed(2) * 100 : ">100";

    const avgConversion = (displayedCampaigns.reduce(
        (total, campaign) => total + (campaign.clicks > 0 ? campaign.purchases1d / campaign.clicks : 0),
        0
    ) / displayedCampaigns.length).toFixed(2) * 100;

    const renderDifference = (difference) => {
        if (difference === undefined) return null;
        const percentage = (difference * 100).toFixed(0);
        if (percentage > 0) {
            return <span style={{ color: 'green' }} >∆ {percentage}%</span>;
        } else if (percentage < 0) {
            return <span style={{ color: 'red' }}>∇ {percentage}%</span>;
        } else {
            return <span style={{ color: 'orange' }}>-</span>;
        }
    };

    const renderReverseDiff = (difference) => {
        if (difference === undefined) return null;
        const percentage = (difference * 100).toFixed(0);
        if (percentage < 0) {
            return <span style={{ color: 'green' }} >∇ {percentage}%</span>;
        } else if (percentage > 0) {
            return <span style={{ color: 'red' }}>∆ {percentage}%</span>;
        } else {
            return <span style={{ color: 'orange' }}>-</span>;
        }
    };


  return (
    <div>
      <div className="table-container">
      <div className="table-wrapper">
        <Table variation="striped" highlightOnHover={true} className="global-styling-table">
          <TableHead>
            <TableRow>
              <TableCell style={{ align: "left" }}>Campaign Name</TableCell>
              <TableCell>Campaign ID</TableCell>
              <TableCell onClick={() => handleSort('impressions')} title="Total number of ad impressions.">Impressions 🔽</TableCell>
              {showAll === false && (
                <>
                  <TableCell onClick={() => handleSort('clicks')} title="Total number of clicks on an ad.">Clicks 🔽</TableCell>
                  <TableCell onClick={() => handleSort('purchases')} title="Number of attributed conversion events occurring within 1 day of an ad click.">Purchases 🔽</TableCell>
                  <TableCell onClick={() => handleSort('costPerClick')} title="Total cost divided by total number of clicks.">Spend Per Click 🔽</TableCell>
                  <TableCell onClick={() => handleSort('costPerPurchase')} title="Total cost divided by purchases. If there are no purchases, then shows cost.">Spend Per Purchase 🔽</TableCell>
                  <TableCell onClick={() => handleSort('acos')} title="Advertising cost of sale. Percentage value of advertising spend divided by advertising sales revenue.">ACOS 🔽</TableCell>
                  <TableCell onClick={() => handleSort('spend')} title="Total cost of ad clicks.">Spend 🔽</TableCell>
                  <TableCell onClick={() => handleSort('sales')} title="Total value of sales occurring within 1 day of an ad click.">Sales Revenue 🔽</TableCell>
                  <TableCell onClick={() => handleSort('conversion')} title="Total purchases occuring within 1 day of an ad click divided by total clicks.">Conversion % 🔽</TableCell>
                </>
              )}
              <TableCell onClick={() => handleSort('status')} title="Click to sort">Campaign Status</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow className="summary-row">
              <TableCell>Total no. campaigns: {displayedCampaigns.length}</TableCell>
              <TableCell></TableCell>
              <TableCell>Total: {totalImpressions}</TableCell>
              {showAll === false && (
                <>
                  <TableCell>Total: {totalClicks}</TableCell>
                  <TableCell>Total: {totalPurchases}</TableCell>
                  <TableCell>Avg: {avgCostPerClick}</TableCell>
                  <TableCell title="Only counting campaigns with purchases">Avg: {avgCostPerPurchase}</TableCell>
                  <TableCell title="Only counting campaigns with purchases">Avg: {avgClickedAcos.toFixed(2)}% Total: {avgAcos}%</TableCell>
                  <TableCell>Total: {totalSpend.toFixed(2)}</TableCell>
                  <TableCell>Total: {totalSales.toFixed(2)}</TableCell>
                  <TableCell>Avg: {avgConversion.toFixed(2)}</TableCell>
                </>
              )}
              <TableCell />
            </TableRow>
            {displayedCampaigns.map((campaign) => (
              <TableRow>
                <TableCell title={campaign.campaignName} onMouseEnter={() => setHoveredCampaignName(campaign.campaignName)} onMouseLeave={() => setHoveredCampaignName(null)} style={{ textAlign: 'left' }}>
                    {showFullNames === false ? (
                        <ReactRouterLink to={`/campaign/${campaign.campaignId}`} component={Link} className="my-link">
                            {campaign.campaignName.split(' - ').slice(0, 3).join(' - ')}
                        </ReactRouterLink>
                    ) : (
                        <ReactRouterLink to={`/campaign/${campaign.campaignId}`} component={Link} className="my-link">
                            {campaign.campaignName}
                        </ReactRouterLink>
                    )} 
                    {noted.includes((campaign.campaignId).toString()) ? (' 📄') : ('')}
                </TableCell>
                <TableCell title="Campaign ID">
                  {<ReactRouterLink to={`/campaign/${campaign.campaignId}`} component={Link} className="my-link">{campaign.campaignId}</ReactRouterLink>}
                </TableCell>
                <TableCell title="Impressions">{campaign.impressions} {renderDifference(differences[campaign.campaignId]?.impressions)}</TableCell>
                {showAll === false && (
                  <>
                    <TableCell title="Clicks">{campaign.clicks} {renderDifference(differences[campaign.campaignId]?.clicks)}</TableCell>
                    <TableCell title="Purchases">{campaign.purchases1d} {renderDifference(differences[campaign.campaignId]?.clicks)}</TableCell>
                    <TableCell title="Spend Per Click">{campaign.costPerClick} {renderDifference(differences[campaign.campaignId]?.costPerClick)}</TableCell>
                    <TableCell title="Spend Per Purchase">
                      {campaign.purchases1d === 0
                        ? campaign.spend
                        : (campaign.spend / campaign.purchases1d).toFixed(2)} {renderDifference(differences[campaign.campaignId]?.spend_per_purchase)}
                    </TableCell>
                    <TableCell title="ACOS">
                      {campaign.sales1d === 0
                        ? campaign.spend === 0
                            ? "0"
                            : ">100"
                        : ((campaign.spend / campaign.sales1d) * 100).toFixed(2)}% {renderReverseDiff(differences[campaign.campaignId]?.acos)}
                    </TableCell>
                    <TableCell title="Spend">{campaign.spend} {renderReverseDiff(differences[campaign.campaignId]?.spend)}</TableCell>
                    <TableCell title="Sales Revenue">{campaign.sales1d} {renderDifference(differences[campaign.campaignId]?.sales1d)}</TableCell>
                    <TableCell title="Conversion %">{((campaign.purchases1d / campaign.clicks) * 100).toFixed(0)} {renderDifference(differences[campaign.campaignId]?.conversion)}</TableCell>
                  </>
                )}
                <TableCell title="Status">{campaign.campaignStatus}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      </div>
    </div>
  );
}

export default SpCampaignList;
