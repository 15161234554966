import { createTodoSecure } from '../graphql/mutations'
import { listTodos } from '../graphql/queries'
import { withAuthenticator, Button, Text, Flex, Heading, Accordion, TextAreaField, SelectField, Divider } from "@aws-amplify/ui-react";
import { React, useCallback, useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { SidePopout } from './SidePopout';
import './css/Notes.css';

const Notes = ({ company, campaigns, dates }) => {
    const [ notes, setNotes ] = useState([])
    const [ campaign, setCampaign ] = useState(campaigns[0]);
    const [ date, setDate ] = useState(dates[0]);
    const [newNoteText, setNewNoteText] = useState("");
    const client = generateClient({ authMode: 'userPool' });

    const fetchNotes = useCallback(async () => {
        const result = await client.graphql({ query: listTodos });
        const sortedNotes = result.data.listTodos.items.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setNotes(sortedNotes);
    }, [setNotes])

    const handleCreateNote = useCallback(async () => {
        if (!newNoteText.trim()) {
            alert("Note text cannot be empty");
            return
        }

        if (campaign === "") {
            alert("Campaign must be set");
            return
        }

        const result = await client.graphql({
            query: createTodoSecure,
            variables: { input: { text: newNoteText, group: company, campaign, date } },
        });
        setNewNoteText("")
        fetchNotes();
    }, [newNoteText, fetchNotes, company])

    useEffect(() => {
        setCampaign(campaigns[0]);
    }, [campaigns])

    useEffect(() => {
        fetchNotes()
    }, [fetchNotes])

    return (
        <Flex direction={"column"} marginLeft="10px" marginRight="10px">
            <Flex justifyContent={'space-between'}>
                <Heading level={1}>Campaign Action Items</Heading>
            </Flex>
            <Accordion.Container defaultValue={["New Note"]}>
                <Accordion.Item value="New Note">
                    <Accordion.Trigger className="accordion-trigger">
                        Add New Note
                        <Accordion.Icon />
                    </Accordion.Trigger>
                    <Accordion.Content className="accordion-content">
                        <SelectField
                            label="Campaign:"
                            options={campaigns}
                            value={campaign}
                            onChange={(e) => setCampaign(e.target.value)}
                            className="notes-select"
                        ></SelectField>
                        <SelectField
                            label="Reporting Period:"
                            options={dates}
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="notes-select"
                        ></SelectField>
                        <TextAreaField
                            descriptiveText="Actions Taken"
                            name="new_note"
                            placeholder="Enter actions here..."
                            value={newNoteText}
                            onChange={(e) => setNewNoteText(e.target.value)}
                            rows={5}
                            width="100%"
                            resize="vertical"
                        />
                        <Button variation="primary" colorTheme="warning" onClick={handleCreateNote} marginTop="1rem">Submit</Button>
                    </Accordion.Content>
                </Accordion.Item>
            </Accordion.Container>
            {notes
                .filter(note => campaigns.includes(note.campaign))
                .map(note => (
                <Flex key={note.id} direction="column" alignItems={'flex-start'} marginTop="1rem">
                    <Divider
                        orientation="horizontal"
                    />
                    <Text fontWeight={650} marginBottom="0.25rem" textAlign="left">{note.campaign}</Text>
                    <Text fontSize="small" fontWeight={650}>Reporting Period: {note.date}</Text>
                    <Text>{note.text}</Text>
                    <Flex justifyContent="space-between" width="100%" alignItems={'flex-start'}>
                        <Text fontSize="small" color="gray.500" marginLeft="auto">
                            {note.author} - {note.createdAt}
                        </Text>
                    </Flex>
                </Flex>
            ))}
        </Flex>
    );
}

export { Notes };
