import React from 'react';
import { Container, Header, Grid, List, Segment, Image, Sidebar } from 'semantic-ui-react';
import { createMedia } from '@artsy/fresnel';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import supinie_solutions from '../supinie_solutions.png';
import 'semantic-ui-css/semantic.min.css';
import { Dashboard } from './Dashboard';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const SmallHeading = ({ mobile }) => (
  <Container text>
    <Header
      as="h1"
      content="Our Products"
      style={{
        color: '#f46f22',
        fontSize: mobile ? '2em' : '3em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '2em',
      }}
    />
  </Container>
);

SmallHeading.propTypes = {
  mobile: PropTypes.bool,
};

class DesktopContainer extends React.Component {
  state = {};

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;
    return (
      <Media greaterThan="mobile">
        <InView onChange={this.toggleFixedMenu}>
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 200, padding: '1em 0em' }}
            vertical
          >
            <SmallHeading />
          </Segment>
        </InView>
        {children}
      </Media>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends React.Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;
    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable>
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 150, padding: '1em 0em' }}
              vertical
            >
              <SmallHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>

      </Media>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const OurProducts = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
              Comprehensive Advertising and Performance Metrics
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
              We provide our customers with a service that delivers both a high-level overview and detailed analytics of their performance across multiple metrics and markets. Utilizing the Amazon Ads API, we track and analyze campaign performance at an individual level.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
              New Features and SP-API Integration
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
              In response to customer requests, we are integrating the SP-API to include organic sales metrics, providing a more comprehensive view of business performance. Our new features will generate detailed reports on overall trends and automate listing and advertising management, pending manual approval from the business.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
              Enhanced Business Insights
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
              These enhancements will offer users a deeper understanding of their business performance within the Amazon marketplace. By reducing manual data collection and analysis, our application enables businesses to focus on content creation, new products, and sales strategies.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
              How We Use Your Data and Analytics
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                We track key metrics such as impressions, clicks, sales, etc. to generate bottom up reports. We use these to flag improvements or regressions in individual campaign performance, as well as generate visual reports of overall advert type and market performance. Alongside reports, we are currently developing tailored machine learning models for each customer. These are given a customers historic report data, and are used to generate custom recommendations for campaign improvements specific to the customers niche.
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image bordered rounded size='large' src={supinie_solutions} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>


    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='About' />
              <List link inverted>
                <List.Item as='a'>Sitemap</List.Item>
                <List.Item><a href="mailto:inquiries@supinie.mozmail.com?body=Supinie Solutions inquiry">Contact Us</a></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Services' />
              <List link inverted>
                <List.Item><a href="/our_products">Our Products</a></List.Item>
                <List.Item><a href="/security">Security</a></List.Item>
                <List.Item><a href="/data_policy">Data Policy</a></List.Item>
                <List.Item><a href="/privacy">Privacy</a></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted style={{ color: '#b9b5b2' }}>
                Supinie Solutions
              </Header>
              <p style={{ fontSize: '1em', color: '#b9b5b2' }}>
                Supinie Solutions LTD, 2023
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
);

export default OurProducts;

    // <Segment style={{ padding: '8em 0em' }} vertical>
    //   <Container>
    //     <Header as='h3' style={{ fontSize: '2em', color: '#f46f22', textAlign: 'center' }}>
    //       Demo
    //     </Header>
    //     <Dashboard company={"ACME Corporation"} />
    //   </Container>
    // </Segment>
