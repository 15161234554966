import React, { useState, useEffect } from 'react';
import { Heading, Grid, View, Loader, Button, Table, TableBody, TableCell, TableRow } from '@aws-amplify/ui-react';
import classNames from "classnames";
import { Line, Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCampaignData, getLastFortnight, getFortnightPeriods } from './Dev';
import { SidePopout } from './SidePopout';
import './css/dashboard.css';
import './css/table.css';
import './css/Graphs.css';


function sortByKey(array, key) {
    return array.sort((a, b) => {
        // Use bracket notation to access the value of the key dynamically
        const valueA = a[key];
        const valueB = b[key];
        // Compare the values
        if (valueA < valueB) {
            return -1;
        }
        if (valueA > valueB) {
            return 1;
        }
        return 0;
    });
}


const CampaignPage = ({ company }) => {
    const navigate = useNavigate();
    const [campaignData, setCampaignData] = useState(undefined);
    const { campaignId } = useParams();
    const lastFortnight = getLastFortnight();
    const fortnights = getFortnightPeriods(lastFortnight).reverse();
    const [isPopoutOpen, setIsPopoutOpen] = useState(false);

    const handleOpenPopout = () => {
        setIsPopoutOpen(true);
    };

    const handleClosePopout = () => {
        setIsPopoutOpen(false);
    }

    useEffect(() => {
        const getCampaignData = async () => {
            try {
                const newCampaign = await fetchCampaignData(campaignId);
                setCampaignData(sortByKey(newCampaign, 'date'));
            } catch (error) {
                console.error('Error fetching campaign data:', error);
            }
        };

        getCampaignData();
    }, [campaignId]);

    if (!campaignData) {
        return <div><Loader size="large"/></div>;
    }

    if (campaignData === []) {
        return (
            <View>
                <Heading level={1} color="#b9b5b2" style={{ margin: '0 auto' }}>Campaign {campaignId} not found.</Heading>
                <Button
                    variation="primary"
                    colorTheme="warning"
                    onClick={() => navigate('/dashboard')}
                >
                    Back to Dashboard
                </Button>
            </View>
        )
    }

    const campaign_type = campaignData[campaignData.length - 1]['adType'].replace("_", " ");

    if (campaign_type === "sponsored products") {
        const fortnights = campaignData.map(item => item.date)
        const campaign_name = campaignData[campaignData.length - 1]['campaignName'];
        const campaign_status = campaignData[campaignData.length - 1]['campaignStatus'];
        let campaign_region = "";
        let budget_amount = "";

        if (campaignData[campaignData.length - 1]['campaignBudgetCurrencyCode'] === "EUR") {
            campaign_region = "🇪🇺";
            budget_amount = "€".concat(campaignData[campaignData.length - 1]['campaignBudgetAmount']);
        } else if (campaignData[campaignData.length - 1]['campaignBudgetCurrencyCode'] === "USD") {
            campaign_region = "🇺🇸";
            budget_amount = "$".concat(campaignData[campaignData.length - 1]['campaignBudgetAmount']);
        } else if (campaignData[campaignData.length - 1]['campaignBudgetCurrencyCode'] === "GBP") {
            campaign_region = "🇬🇧";
            budget_amount = "£".concat(campaignData[campaignData.length - 1]['campaignBudgetAmount']);
        }


        const budget_type = campaignData[campaignData.length - 1]['campaignBudgetType'].split("_")[0];

        const spend_lifetime = campaignData.map(item => item.spend).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const spend_lifetime_avg = (spend_lifetime / campaignData.length).toFixed(2);
        const spend_3 = campaignData.map(item => item.spend).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const spend_3_avg = (spend_3 / 3).toFixed(2);
        const spend_last = campaignData[campaignData.length - 1].spend;
        
        const sales_lifetime = campaignData.map(item => item.sales1d).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const sales_lifetime_avg = (sales_lifetime / campaignData.length).toFixed(2);
        const sales_3 = campaignData.map(item => item.sales1d).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const sales_3_avg = (sales_3 / 3).toFixed(2);
        const sales_last = campaignData[campaignData.length - 1].sales1d;
        
        let acos_lifetime = 0;
        if (spend_lifetime > 0 && sales_lifetime > 0) {
            acos_lifetime = ((spend_lifetime / sales_lifetime) * 100).toFixed(2);
        } else if (spend_lifetime > 0) {
            acos_lifetime = "> 100";
        }
        let acos_3 = 0;
        if (spend_3 > 0 && sales_3 > 0) {
            acos_3 = ((spend_3 / sales_3) * 100).toFixed(2);
        } else if (spend_3 > 0) {
            acos_3 = "> 100";
        }
        let acos_last = 0;
        if (spend_last > 0 && sales_last > 0) {
            acos_last = ((spend_last / sales_last) * 100).toFixed(2);
        } else if (spend_last > 0) {
            acos_last = "> 100";
        }

        const impressions_lifetime = campaignData.map(item => item.impressions).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const impressions_lifetime_avg = (impressions_lifetime / campaignData.length).toFixed(0);
        const impressions_3 = campaignData.map(item => item.impressions).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const impressions_3_avg = (impressions_3 / 3).toFixed(0);
        const impressions_last = campaignData[campaignData.length - 1].impressions;

        const clicks_lifetime = campaignData.map(item => item.clicks).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const clicks_lifetime_avg = (clicks_lifetime / campaignData.length).toFixed(0);
        const clicks_3 = campaignData.map(item => item.clicks).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const clicks_3_avg = (clicks_3 / 3).toFixed(0);
        const clicks_last = campaignData[campaignData.length - 1].clicks;


        const purchases_lifetime = campaignData.map(item => item.purchases1d).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const purchases_lifetime_avg = (purchases_lifetime / campaignData.length).toFixed(0);
        const purchases_3 = campaignData.map(item => item.purchases1d).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const purchases_3_avg = (purchases_3 / 3).toFixed(0);
        const purchases_last = campaignData[campaignData.length - 1].purchases1d;

        let cpc_lifetime = 0;
        let conversion_lifetime = 0;
        if (clicks_lifetime > 0) {
            cpc_lifetime = (spend_lifetime / clicks_lifetime).toFixed(2);
            conversion_lifetime = ((purchases_lifetime / clicks_lifetime) * 100).toFixed(0);
        } else {
            cpc_lifetime = spend_lifetime;
        }
        let cpc_3 = 0;
        let conversion_3 = 0;
        if (clicks_3 > 0) {
            cpc_3 = (spend_3 / clicks_3).toFixed(2);
            conversion_3 = ((purchases_3 / clicks_3) * 100).toFixed(0);
        } else {
            cpc_3 = spend_3;
        }
        let cpc_last = 0;
        let conversion_last = 0;
        if (clicks_last > 0) {
            cpc_last = (spend_last / clicks_last).toFixed(2);
            conversion_last = ((purchases_last / clicks_last) * 100).toFixed(0);
        } else {
            cpc_last = spend_last;
        }
        

        let chart_options = {
            maintainAspectRatio: true,
            legend: {
                display: false,
            },
            tooltips: {
                backgroundColor: "#f5f5f5",
                titleFontColor: "#333",
                bodyFontColor: "#666",
                bodySpacing: 4,
                xPadding: 12,
                mode: "nearest",
                intersect: 10,
                position: "nearest",
                callbacks: {
                    label: (tooltipItem, data) => {
                        const label = data.datasets[tooltipItem.datasetIndex].label || '';
                        const value = tooltipItem.yLabel || '';

                        const dateRange = fortnights[tooltipItem.index] || '';
                        return `${label}: ${value} (${dateRange})`;
                    },
                },
            },
            responsive: true,
            scales: {
                yAxes: {
                    barPercentage: 1.6,
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(29,140,248,0.0)",
                        zeroLineColor: "transparent",
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9a9a9a",
                    },
                },
                xAxes: {
                    barPercentage: 1.6,
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(29,140,248,0.1)",
                        zeroLineColor: "transparent",
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9a9a9a", 
                        // callback: (value, index, values) => {
                        //     return value - fortnights.length; // Count down to -10 as leftmost point
                        // },
                    },
                },
            },
        };


        let impressions_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.impressions) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => impressions_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => impressions_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let clicks_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.clicks) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => clicks_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => clicks_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let purchases_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.purchases1d) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => purchases_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => purchases_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let spend_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.spend) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => spend_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => spend_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let CPC_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.clicks !== 0) {
                                    return item.cost / item.clicks;
                                } else {
                                    return item.cost;
                                }
                            }) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => cpc_lifetime) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => cpc_3) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let spend_per_purchase_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.purchases1d !== 0) {
                                    return item.spend / item.purchases1d;
                                } else {
                                    return item.spend;
                                }
                            }) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };

        let acos_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.sales1d !== 0) {
                                    return (item.spend / item.sales1d) * 100;
                                } else {
                                    return null;
                                }
                            }) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => acos_lifetime) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => acos_3) || [],
                        },
                        {
                            label: "Profitability",
                            fill: false,
                            borderColor: "#a9b665", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => 25) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let sales_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.sales1d) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => sales_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => sales_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let ctr_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.clickThroughRate) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let conversion_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.clicks !== 0) {
                                    return (item.purchases1d / item.clicks) * 100;
                                } else {
                                    return null;
                                }
                            }) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => conversion_lifetime) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => conversion_3) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let top_of_search_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.topOfSearchImpressionShare) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        return (
            <div>
                <div style={{ marginBottom: '2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button
                        variation="primary"
                        colorTheme="warning"
                        onClick={() => navigate('/sp')}
                        style={{ marginRight: 'auto' }}
                    >
                        &lt; Campaign List
                    </Button>
                    <Heading level={1} color="#b9b5b2" style={{ margin: '0 auto' }}>{campaign_name} ({campaignId})</Heading>
                    <Button onClick={handleOpenPopout}>✍️</Button>
                    <SidePopout 
                        company={company}
                        campaigns={[`${campaign_name} - ${campaignId}`]}
                        dates={fortnights}
                        isOpen={isPopoutOpen}
                        onClose={handleClosePopout}
                    />
                </div>
                <Grid
                    columnGap="2rem"
                    rowGap="0.5rem"
                    templateColumns="1fr 3fr"
                    templateRows="1fr"
                >
                    <View>
                        <Grid 
                            columnGap="2rem"
                            rowGap="0.5rem"
                            templateColumns="1fr 1fr"
                            templateRows="1fr"
                        >
                            <View style={{ textAlign: 'left' }}>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Status:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Type:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Region:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Budget Amount:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Budget Type:</Heading> 
                            </View>
                            <View style={{ textAlign: 'right' }}>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{campaign_status}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{campaign_type}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{campaign_region}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{budget_amount}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{budget_type}</Heading> 
                            </View>
                        </Grid>
                        <Table
                            highlightOnHover={true}
                            className="global-styling-table"
                        >
                        <TableBody>
                            <TableRow>
                              <TableCell as="th" />
                              <TableCell as="th">Lifetime Avg.</TableCell>
                              <TableCell as="th">Last 3 Periods Avg.</TableCell>
                              <TableCell as="th">Last Period</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">ACOS</TableCell>
                              <TableCell>{acos_lifetime} %</TableCell>
                              <TableCell>{acos_3} %</TableCell>
                              <TableCell>{acos_last} %</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">CPC</TableCell>
                              <TableCell>{cpc_lifetime}</TableCell>
                              <TableCell>{cpc_3}</TableCell>
                              <TableCell>{cpc_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Spend</TableCell>
                              <TableCell>{spend_lifetime_avg}</TableCell>
                              <TableCell>{spend_3_avg}</TableCell>
                              <TableCell>{spend_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Sales</TableCell>
                              <TableCell>{sales_lifetime_avg}</TableCell>
                              <TableCell>{sales_3_avg}</TableCell>
                              <TableCell>{sales_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Impressions</TableCell>
                              <TableCell>{impressions_lifetime_avg}</TableCell>
                              <TableCell>{impressions_3_avg}</TableCell>
                              <TableCell>{impressions_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Clicks</TableCell>
                              <TableCell>{clicks_lifetime_avg}</TableCell>
                              <TableCell>{clicks_3_avg}</TableCell>
                              <TableCell>{clicks_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Purchases</TableCell>
                              <TableCell>{purchases_lifetime_avg}</TableCell>
                              <TableCell>{purchases_3_avg}</TableCell>
                              <TableCell>{purchases_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Conversion</TableCell>
                              <TableCell>{conversion_lifetime} %</TableCell>
                              <TableCell>{conversion_3} %</TableCell>
                              <TableCell>{conversion_last} %</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    </View>
                    <View className="graph-container">
                        <Grid
                            columnGap="0.5rem"
                            rowGap="0.5rem"
                            templateColumns="1fr 1fr"
                            templateRows="1fr 1fr 1fr 1fr 1fr"
                        >
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Impressions</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={impressions_graph.data}
                                            options={impressions_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Clicks</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={clicks_graph.data}
                                            options={clicks_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Purchases</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={purchases_graph.data}
                                            options={purchases_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">ACOS</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={acos_graph.data}
                                            options={acos_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Spend</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={spend_graph.data}
                                            options={spend_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Sales Revenue</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={sales_graph.data}
                                            options={sales_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Spend Per Click</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={CPC_graph.data}
                                            options={CPC_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Spend Per Purchase</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={spend_per_purchase_graph.data}
                                            options={spend_per_purchase_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Click Through Rate</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={ctr_graph.data}
                                            options={ctr_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Conversion Rate</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={conversion_graph.data}
                                            options={conversion_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Top of Search %</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={top_of_search_graph.data}
                                            options={top_of_search_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Grid>        
                    </View>
                </Grid>
            </div>
        )
    } else if (campaign_type === "sponsored brands") {
        const fortnights = campaignData.map(item => item.date)
        const campaign_name = campaignData[campaignData.length - 1]['campaignName'];
        const campaign_status = campaignData[campaignData.length - 1]['campaignStatus'];
        let campaign_region = "";
        let budget_amount = "";

        if (campaignData[campaignData.length - 1]['campaignBudgetCurrencyCode'] === "EUR") {
            campaign_region = "🇪🇺";
            budget_amount = "€".concat(campaignData[campaignData.length - 1]['campaignBudgetAmount']);
        } else if (campaignData[campaignData.length - 1]['campaignBudgetCurrencyCode'] === "USD") {
            campaign_region = "🇺🇸";
            budget_amount = "$".concat(campaignData[campaignData.length - 1]['campaignBudgetAmount']);
        } else if (campaignData[campaignData.length - 1]['campaignBudgetCurrencyCode'] === "GBP") {
            campaign_region = "🇬🇧";
            budget_amount = "£".concat(campaignData[campaignData.length - 1]['campaignBudgetAmount']);
        }


        const budget_type = campaignData[campaignData.length - 1]['campaignBudgetType'].split("_")[0];
        const cost_type = campaignData[campaignData.length - 1]['costType'];

        const spend_lifetime = campaignData.map(item => item.cost).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const spend_lifetime_avg = (spend_lifetime / campaignData.length).toFixed(2);
        const spend_3 = campaignData.map(item => item.cost).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const spend_3_avg = (spend_3 / 3).toFixed(2);
        const spend_last = campaignData[campaignData.length - 1].cost;
        
        const sales_lifetime = campaignData.map(item => item.salesClicks).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const sales_lifetime_avg = (sales_lifetime / campaignData.length).toFixed(2);
        const sales_3 = campaignData.map(item => item.salesClicks).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const sales_3_avg = (sales_3 / 3).toFixed(2);
        const sales_last = campaignData[campaignData.length - 1].salesClicks;
        
        let acos_lifetime = 0;
        if (spend_lifetime > 0 && sales_lifetime > 0) {
            acos_lifetime = ((spend_lifetime / sales_lifetime) * 100).toFixed(2);
        } else if (spend_lifetime > 0) {
            acos_lifetime = "> 100";
        }
        let acos_3 = 0;
        if (spend_3 > 0 && sales_3 > 0) {
            acos_3 = ((spend_3 / sales_3) * 100).toFixed(2);
        } else if (spend_3 > 0) {
            acos_3 = "> 100";
        }
        let acos_last = 0;
        if (spend_last > 0 && sales_last > 0) {
            acos_last = ((spend_last / sales_last) * 100).toFixed(2);
        } else if (spend_last > 0) {
            acos_last = "> 100";
        }

        const impressions_lifetime = campaignData.map(item => item.viewableImpressions).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const impressions_lifetime_avg = (impressions_lifetime / campaignData.length).toFixed(0);
        const impressions_3 = campaignData.map(item => item.viewableImpressions).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const impressions_3_avg = (impressions_3 / 3).toFixed(0);
        const impressions_last = campaignData[campaignData.length - 1].viewableImpressions;

        const clicks_lifetime = campaignData.map(item => item.clicks).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const clicks_lifetime_avg = (clicks_lifetime / campaignData.length).toFixed(0);
        const clicks_3 = campaignData.map(item => item.clicks).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const clicks_3_avg = (clicks_3 / 3).toFixed(0);
        const clicks_last = campaignData[campaignData.length - 1].clicks;


        const add_to_cart_lifetime = campaignData.map(item => item.addToCartClicks).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const add_to_cart_lifetime_avg = (add_to_cart_lifetime / campaignData.length).toFixed(0);
        const add_to_cart_3 = campaignData.map(item => item.addToCartClicks).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const add_to_cart_3_avg = (add_to_cart_3 / 3).toFixed(0);
        const add_to_cart_last = campaignData[campaignData.length - 1].addToCartClicks;


        const purchases_lifetime = campaignData.map(item => item.purchasesClicks).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const purchases_lifetime_avg = (purchases_lifetime / campaignData.length).toFixed(0);
        const purchases_3 = campaignData.map(item => item.purchasesClicks).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const purchases_3_avg = (purchases_3 / 3).toFixed(0);
        const purchases_last = campaignData[campaignData.length - 1].purchasesClicks;

        let cpc_lifetime = 0;
        let conversion_lifetime = 0;
        if (clicks_lifetime > 0) {
            cpc_lifetime = (spend_lifetime / clicks_lifetime).toFixed(2);
            conversion_lifetime = ((purchases_lifetime / clicks_lifetime) * 100).toFixed(0);
        } else {
            cpc_lifetime = spend_lifetime;
        }
        let cpc_3 = 0;
        let conversion_3 = 0;
        if (clicks_3 > 0) {
            cpc_3 = (spend_3 / clicks_3).toFixed(2);
            conversion_3 = ((purchases_3 / clicks_3) * 100).toFixed(0);
        } else {
            cpc_3 = spend_3;
        }
        let cpc_last = 0;
        let conversion_last = 0;
        if (clicks_last > 0) {
            cpc_last = (spend_last / clicks_last).toFixed(2);
            conversion_last = ((purchases_last / clicks_last) * 100).toFixed(0);
        } else {
            cpc_last = spend_last;
        }

        let chart_options = {
            maintainAspectRatio: true,
            legend: {
                display: false,
            },
            tooltips: {
                backgroundColor: "#f5f5f5",
                titleFontColor: "#333",
                bodyFontColor: "#666",
                bodySpacing: 4,
                xPadding: 12,
                mode: "nearest",
                intersect: 0,
                position: "nearest",
                callbacks: {
                    label: (tooltipItem, data) => {
                        const label = data.datasets[tooltipItem.datasetIndex].label || '';
                        const value = tooltipItem.yLabel || '';

                        const dateRange = fortnights[tooltipItem.index] || '';
                        return `${label}: ${value} (${dateRange})`;
                    },
                },
            },
            responsive: true,
            scales: {
                yAxes: {
                    barPercentage: 1.6,
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(29,140,248,0.0)",
                        zeroLineColor: "transparent",
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9a9a9a",
                    },
                },
                xAxes: {
                    barPercentage: 1.6,
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(29,140,248,0.1)",
                        zeroLineColor: "transparent",
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9a9a9a", 
                        // callback: (value, index, values) => {
                        //     return value - fortnights.length; // Count down to -10 as leftmost point
                        // },
                    },
                },
            },
        };


        let impressions_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.viewableImpressions) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => impressions_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => impressions_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let clicks_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.clicks) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => clicks_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => clicks_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let add_to_cart_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.addToCartClicks) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => add_to_cart_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => add_to_cart_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let purchases_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.purchasesClicks) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => purchases_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => purchases_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let spend_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.cost) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => spend_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => spend_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let CPC_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.clicks !== 0) {
                                    return item.cost / item.clicks;
                                } else {
                                    return item.cost;
                                }
                            }) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => cpc_lifetime) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => cpc_3) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let spend_per_purchase_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.purchasesClicks !== 0) {
                                    return item.cost / item.purchasesClicks;
                                } else {
                                    return item.cost;
                                }
                            }) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };

        let acos_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.salesClicks !== 0) {
                                    return (item.cost / item.salesClicks) * 100;
                                } else {
                                    return null;
                                }
                            }) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => acos_lifetime) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => acos_3) || [],
                        },
                        {
                            label: "Profitability",
                            fill: false,
                            borderColor: "#a9b665", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => 25) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let sales_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.salesClicks) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => sales_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => sales_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let add_to_cart_rate_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.addToCartRate) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let conversion_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.clicks !== 0) {
                                    return (item.purchasesClicks / item.clicks) * 100;
                                } else {
                                    return null;
                                }
                            }) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => conversion_lifetime) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => conversion_3) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };

        let detail_page_views_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.detailPageViewsClicks) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let viewability_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.viewabilityRate) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let ctr_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.viewClickThroughRate) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        return (
            <div>
                <div style={{ marginBottom: '2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button
                        variation="primary"
                        colorTheme="warning"
                        onClick={() => navigate('/sb')}
                        style={{ marginRight: 'auto' }}
                    >
                        &lt; Campaign List
                    </Button>
                    <Heading level={1} color="#b9b5b2" style={{ margin: '0 auto' }}>{campaign_name} ({campaignId})</Heading>
                    <Button onClick={handleOpenPopout}>✍️</Button>
                    <SidePopout 
                        company={company}
                        campaigns={[`${campaign_name} - ${campaignId}`]}
                        dates={fortnights}
                        isOpen={isPopoutOpen}
                        onClose={handleClosePopout}
                    />
                </div>
                <Grid
                    columnGap="2rem"
                    rowGap="0.5rem"
                    templateColumns="1fr 3fr"
                    templateRows="1fr"
                >
                    <View>
                        <Grid 
                            columnGap="2rem"
                            rowGap="0.5rem"
                            templateColumns="1fr 1fr"
                            templateRows="1fr"
                        >
                            <View style={{ textAlign: 'left' }}>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Status:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Type:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Cost Type:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Region:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Budget Amount:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Budget Type:</Heading> 
                            </View>
                            <View style={{ textAlign: 'right' }}>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{campaign_status}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{campaign_type}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{cost_type}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{campaign_region}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{budget_amount}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{budget_type}</Heading> 
                            </View>
                        </Grid>
                        <Table
                            highlightOnHover={true}
                            className="global-styling-table"
                        >
                        <TableBody>
                            <TableRow>
                              <TableCell as="th" />
                              <TableCell as="th">Lifetime Avg.</TableCell>
                              <TableCell as="th">Last 3 Periods Avg.</TableCell>
                              <TableCell as="th">Last Period</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">ACOS</TableCell>
                              <TableCell>{acos_lifetime} %</TableCell>
                              <TableCell>{acos_3} %</TableCell>
                              <TableCell>{acos_last} %</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">CPC</TableCell>
                              <TableCell>{cpc_lifetime}</TableCell>
                              <TableCell>{cpc_3}</TableCell>
                              <TableCell>{cpc_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Spend</TableCell>
                              <TableCell>{spend_lifetime_avg}</TableCell>
                              <TableCell>{spend_3_avg}</TableCell>
                              <TableCell>{spend_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Sales</TableCell>
                              <TableCell>{sales_lifetime_avg}</TableCell>
                              <TableCell>{sales_3_avg}</TableCell>
                              <TableCell>{sales_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Impressions</TableCell>
                              <TableCell>{impressions_lifetime_avg}</TableCell>
                              <TableCell>{impressions_3_avg}</TableCell>
                              <TableCell>{impressions_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Clicks</TableCell>
                              <TableCell>{clicks_lifetime_avg}</TableCell>
                              <TableCell>{clicks_3_avg}</TableCell>
                              <TableCell>{clicks_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Purchases</TableCell>
                              <TableCell>{purchases_lifetime_avg}</TableCell>
                              <TableCell>{purchases_3_avg}</TableCell>
                              <TableCell>{purchases_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Conversion</TableCell>
                              <TableCell>{conversion_lifetime} %</TableCell>
                              <TableCell>{conversion_3} %</TableCell>
                              <TableCell>{conversion_last} %</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    </View>
                    <View className="graph-container">
                        <Grid
                            columnGap="0.5rem"
                            rowGap="0.5rem"
                            templateColumns="1fr 1fr"
                            templateRows="1fr 1fr 1fr 1fr 1fr"
                        >
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Impressions</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={impressions_graph.data}
                                            options={impressions_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Clicks</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={clicks_graph.data}
                                            options={clicks_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Added To Cart</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={add_to_cart_graph.data}
                                            options={add_to_cart_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Purchases</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={purchases_graph.data}
                                            options={purchases_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">ACOS</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={acos_graph.data}
                                            options={acos_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Conversion Rate</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={conversion_graph.data}
                                            options={conversion_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Spend</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={spend_graph.data}
                                            options={spend_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Sales Revenue</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={sales_graph.data}
                                            options={sales_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Spend Per Click</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={CPC_graph.data}
                                            options={CPC_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Spend Per Purchase</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={spend_per_purchase_graph.data}
                                            options={spend_per_purchase_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Detailed Page Views</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={detail_page_views_graph.data}
                                            options={detail_page_views_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Click Through Rate</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={ctr_graph.data}
                                            options={ctr_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Add to Cart %</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={add_to_cart_rate_graph.data}
                                            options={add_to_cart_rate_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Grid>        
                    </View>
                </Grid>
            </div>
        )
    } else if (campaign_type === "sponsored display") {
        const fortnights = campaignData.map(item => item.date)
        const campaign_name = campaignData[campaignData.length - 1]['campaignName'];
        const campaign_status = campaignData[campaignData.length - 1]['campaignStatus'];
        let campaign_region = "";
        let budget_amount = "";

        if (campaignData[campaignData.length - 1]['campaignBudgetCurrencyCode'] === "EUR") {
            campaign_region = "🇪🇺";
            budget_amount = "€".concat(campaignData[campaignData.length - 1]['campaignBudgetAmount']);
        } else if (campaignData[campaignData.length - 1]['campaignBudgetCurrencyCode'] === "USD") {
            campaign_region = "🇺🇸";
            budget_amount = "$".concat(campaignData[campaignData.length - 1]['campaignBudgetAmount']);
        } else if (campaignData[campaignData.length - 1]['campaignBudgetCurrencyCode'] === "GBP") {
            campaign_region = "🇬🇧";
            budget_amount = "£".concat(campaignData[campaignData.length - 1]['campaignBudgetAmount']);
        }

        const cost_type = campaignData[campaignData.length - 1]['costType'];

        const spend_lifetime = campaignData.map(item => item.cost).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const spend_lifetime_avg = (spend_lifetime / campaignData.length).toFixed(2);
        const spend_3 = campaignData.map(item => item.cost).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const spend_3_avg = (spend_3 / 3).toFixed(2);
        const spend_last = campaignData[campaignData.length - 1].cost;
        
        const sales_lifetime = campaignData.map(item => item.salesClicks).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const sales_lifetime_avg = (sales_lifetime / campaignData.length).toFixed(2);
        const sales_3 = campaignData.map(item => item.salesClicks).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const sales_3_avg = (sales_3 / 3).toFixed(2);
        const sales_last = campaignData[campaignData.length - 1].salesClicks;
        
        let acos_lifetime = 0;
        if (spend_lifetime > 0 && sales_lifetime > 0) {
            acos_lifetime = ((spend_lifetime / sales_lifetime) * 100).toFixed(2);
        } else if (spend_lifetime > 0) {
            acos_lifetime = "> 100";
        }
        let acos_3 = 0;
        if (spend_3 > 0 && sales_3 > 0) {
            acos_3 = ((spend_3 / sales_3) * 100).toFixed(2);
        } else if (spend_3 > 0) {
            acos_3 = "> 100";
        }
        let acos_last = 0;
        if (spend_last > 0 && sales_last > 0) {
            acos_last = ((spend_last / sales_last) * 100).toFixed(2);
        } else if (spend_last > 0) {
            acos_last = "> 100";
        }

        const impressions_lifetime = campaignData.map(item => item.impressionsViews).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const impressions_lifetime_avg = (impressions_lifetime / campaignData.length).toFixed(0);
        const impressions_3 = campaignData.map(item => item.impressionsViews).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const impressions_3_avg = (impressions_3 / 3).toFixed(0);
        const impressions_last = campaignData[campaignData.length - 1].impressionsViews;
        
        const add_to_cart_lifetime = campaignData.map(item => item.addToCartClicks).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const add_to_cart_lifetime_avg = (add_to_cart_lifetime / campaignData.length).toFixed(0);
        const add_to_cart_3 = campaignData.map(item => item.addToCartClicks).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const add_to_cart_3_avg = (add_to_cart_3 / 3).toFixed(0);
        const add_to_cart_last = campaignData[campaignData.length - 1].addToCartClicks;

        const clicks_lifetime = campaignData.map(item => item.clicks).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const clicks_lifetime_avg = (clicks_lifetime / campaignData.length).toFixed(0);
        const clicks_3 = campaignData.map(item => item.clicks).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const clicks_3_avg = (clicks_3 / 3).toFixed(0);
        const clicks_last = campaignData[campaignData.length - 1].clicks;


        const purchases_lifetime = campaignData.map(item => item.purchasesClicks).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const purchases_lifetime_avg = (purchases_lifetime / campaignData.length).toFixed(0);
        const purchases_3 = campaignData.map(item => item.purchasesClicks).slice(-3).reduce((accumulator, current) => {
            return accumulator + current
        }, 0);
        const purchases_3_avg = (purchases_3 / 3).toFixed(0);
        const purchases_last = campaignData[campaignData.length - 1].purchasesClicks;

        let cpc_lifetime = 0;
        let conversion_lifetime = 0;
        if (clicks_lifetime > 0) {
            cpc_lifetime = (spend_lifetime / clicks_lifetime).toFixed(2);
            conversion_lifetime = ((purchases_lifetime / clicks_lifetime) * 100).toFixed(0);
        } else {
            cpc_lifetime = spend_lifetime;
        }
        let cpc_3 = 0;
        let conversion_3 = 0;
        if (clicks_3 > 0) {
            cpc_3 = (spend_3 / clicks_3).toFixed(2);
            conversion_3 = ((purchases_3 / clicks_3) * 100).toFixed(0);
        } else {
            cpc_3 = spend_3;
        }
        let cpc_last = 0;
        let conversion_last = 0;
        if (clicks_last > 0) {
            cpc_last = (spend_last / clicks_last).toFixed(2);
            conversion_last = ((purchases_last / clicks_last) * 100).toFixed(0);
        } else {
            cpc_last = spend_last;
        }


        let chart_options = {
            maintainAspectRatio: true,
            legend: {
                display: false,
            },
            tooltips: {
                backgroundColor: "#f5f5f5",
                titleFontColor: "#333",
                bodyFontColor: "#666",
                bodySpacing: 4,
                xPadding: 12,
                mode: "nearest",
                intersect: 0,
                position: "nearest",
                callbacks: {
                    label: (tooltipItem, data) => {
                        const label = data.datasets[tooltipItem.datasetIndex].label || '';
                        const value = tooltipItem.yLabel || '';

                        const dateRange = fortnights[tooltipItem.index] || '';
                        return `${label}: ${value} (${dateRange})`;
                    },
                },
            },
            responsive: true,
            scales: {
                yAxes: {
                    barPercentage: 1.6,
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(29,140,248,0.0)",
                        zeroLineColor: "transparent",
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9a9a9a",
                    },
                },
                xAxes: {
                    barPercentage: 1.6,
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(29,140,248,0.1)",
                        zeroLineColor: "transparent",
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9a9a9a", 
                        // callback: (value, index, values) => {
                        //     return value - fortnights.length; // Count down to -10 as leftmost point
                        // },
                    },
                },
            },
        };


        let impressions_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.impressionsViews) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => impressions_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => impressions_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let clicks_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.clicks) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => clicks_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => clicks_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let add_to_cart_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.addToCartClicks) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => add_to_cart_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => add_to_cart_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let purchases_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.purchasesClicks) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => purchases_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => purchases_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let spend_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.cost) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => spend_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => spend_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let CPC_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.clicks !== 0) {
                                    return item.cost / item.clicks;
                                } else {
                                    return item.cost;
                                }
                            }) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => cpc_lifetime) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => cpc_3) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let spend_per_purchase_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.purchasesClicks !== 0) {
                                    return item.cost / item.purchasesClicks;
                                } else {
                                    return item.cost;
                                }
                            }) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };

        let acos_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.salesClicks !== 0) {
                                    return (item.cost / item.salesClicks) * 100;
                                } else {
                                    return null;
                                }
                            }) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => acos_lifetime) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => acos_3) || [],
                        },
                        {
                            label: "Profitability",
                            fill: false,
                            borderColor: "#a9b665", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => 25) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let sales_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.salesClicks) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => sales_lifetime_avg) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => sales_3_avg) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let add_to_cart_rate_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.addToCartRate) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let conversion_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => {
                                if (item.clicks !== 0) {
                                    return (item.purchasesClicks / item.clicks) * 100;
                                } else {
                                    return null;
                                }
                            }) || [],
                        },
                        {
                            label: "Lifetime Avg.",
                            fill: false,
                            borderColor: "#89949F", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => conversion_lifetime) || [],
                        },
                        {
                            label: "3 Period Avg.",
                            fill: false,
                            borderColor: "#5C6670", // Grey
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 0,
                            data: campaignData.map(item => conversion_3) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let detail_page_views_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.detailPageViewsClicks) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let viewability_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.viewabilityRate) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        let ctr_graph = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
                redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
                redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

                return {
                    labels: fortnights,
                    datasets: [
                        {
                            label: "",
                            fill: true,
                            backgroundColor: redGradientStroke,
                            borderColor: "#FF5722", // Red
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#FF5722", // Red
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#FF5722", // Red
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            // hoverRadius: 10,
                            pointRadius: 4,
                            data: campaignData.map(item => item.viewClickThroughRate) || [],
                        },
                    ],
                };
            },
            options: chart_options,
        };


        return (
            <div>
                <div style={{ marginBottom: '2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button
                        variation="primary"
                        colorTheme="warning"
                        onClick={() => navigate('/sd')}
                        style={{ marginRight: 'auto' }}
                    >
                        &lt; Campaign List
                    </Button>
                    <Heading level={1} color="#b9b5b2" style={{ margin: '0 auto' }}>{campaign_name} ({campaignId})</Heading>
                    <Button onClick={handleOpenPopout}>✍️</Button>
                    <SidePopout 
                        company={company}
                        campaigns={[`${campaign_name} - ${campaignId}`]}
                        dates={fortnights}
                        isOpen={isPopoutOpen}
                        onClose={handleClosePopout}
                    />
                </div>
                <Grid
                    columnGap="2rem"
                    rowGap="0.5rem"
                    templateColumns="1fr 3fr"
                    templateRows="1fr"
                >
                    <View>
                        <Grid 
                            columnGap="2rem"
                            rowGap="0.5rem"
                            templateColumns="1fr 1fr"
                            templateRows="1fr"
                        >
                            <View style={{ textAlign: 'left' }}>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Status:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Type:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Cost Type:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Region:</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>Budget Amount:</Heading>
                            </View>
                            <View style={{ textAlign: 'right' }}>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{campaign_status}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{campaign_type}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{cost_type}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{campaign_region}</Heading>
                                <Heading level={4} color="#b9b5b2" style={{ marginBottom: '1rem' }}>{budget_amount}</Heading>
                            </View>
                        </Grid>
                        <Table
                            highlightOnHover={true}
                            className="global-styling-table"
                        >
                        <TableBody>
                            <TableRow>
                              <TableCell as="th" />
                              <TableCell as="th">Lifetime Avg.</TableCell>
                              <TableCell as="th">Last 3 Periods Avg.</TableCell>
                              <TableCell as="th">Last Period</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">ACOS</TableCell>
                              <TableCell>{acos_lifetime} %</TableCell>
                              <TableCell>{acos_3} %</TableCell>
                              <TableCell>{acos_last} %</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">CPC</TableCell>
                              <TableCell>{cpc_lifetime}</TableCell>
                              <TableCell>{cpc_3}</TableCell>
                              <TableCell>{cpc_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Spend</TableCell>
                              <TableCell>{spend_lifetime_avg}</TableCell>
                              <TableCell>{spend_3_avg}</TableCell>
                              <TableCell>{spend_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Sales</TableCell>
                              <TableCell>{sales_lifetime_avg}</TableCell>
                              <TableCell>{sales_3_avg}</TableCell>
                              <TableCell>{sales_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Impressions</TableCell>
                              <TableCell>{impressions_lifetime_avg}</TableCell>
                              <TableCell>{impressions_3_avg}</TableCell>
                              <TableCell>{impressions_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Clicks</TableCell>
                              <TableCell>{clicks_lifetime_avg}</TableCell>
                              <TableCell>{clicks_3_avg}</TableCell>
                              <TableCell>{clicks_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Purchases</TableCell>
                              <TableCell>{purchases_lifetime_avg}</TableCell>
                              <TableCell>{purchases_3_avg}</TableCell>
                              <TableCell>{purchases_last}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell as="th">Conversion</TableCell>
                              <TableCell>{conversion_lifetime} %</TableCell>
                              <TableCell>{conversion_3} %</TableCell>
                              <TableCell>{conversion_last} %</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    </View>
                    <View className="graph-container">
                        <Grid
                            columnGap="0.5rem"
                            rowGap="0.5rem"
                            templateColumns="1fr 1fr"
                            templateRows="1fr 1fr 1fr 1fr 1fr"
                        >
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Impressions</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={impressions_graph.data}
                                            options={impressions_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Clicks</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={clicks_graph.data}
                                            options={clicks_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Added To Cart</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={add_to_cart_graph.data}
                                            options={add_to_cart_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Purchases</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={purchases_graph.data}
                                            options={purchases_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">ACOS</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={acos_graph.data}
                                            options={acos_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Conversion Rate</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={conversion_graph.data}
                                            options={conversion_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Spend</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={spend_graph.data}
                                            options={spend_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Sales Revenue</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={sales_graph.data}
                                            options={sales_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Spend Per Click</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={CPC_graph.data}
                                            options={CPC_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Spend Per Purchase</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={spend_per_purchase_graph.data}
                                            options={spend_per_purchase_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Detailed Page Views</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={detail_page_views_graph.data}
                                            options={detail_page_views_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Click Through Rate</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={ctr_graph.data}
                                            options={ctr_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col className="text-left" sm="6">
                                            <CardTitle tag="h3">Add to Cart %</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={add_to_cart_rate_graph.data}
                                            options={add_to_cart_rate_graph.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Grid>        
                    </View>
                </Grid>
            </div>
        )
    }
}

export { CampaignPage };
