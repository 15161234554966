import { useState } from 'react';
import { Button, Flex, ScrollView } from '@aws-amplify/ui-react';
import { Notes } from './Notes.js';

const SidePopout = ({ company, campaigns, dates, isOpen, onClose }) => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            right: isOpen ? 0 : '-500px',
            width: '500px',
            height: '100%',
            backgroundColor: '#bfbfbf',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
            transition: 'right 0.3s ease',
            zIndex: 1000
        }}>
            <Flex direction="column" style={{ height: '100%' }}>
                <Button onClick={onClose} style={{ alignSelf: 'flex-end', margin: '10px'}}>❌</Button>
                <ScrollView style={{ flex: 1 }}>
                    <Notes company={company} campaigns={campaigns} dates={dates}/>
                </ScrollView>
            </Flex>
        </div>
    );
};

export { SidePopout };
