import React from 'react';
import { Container, Header, Grid, List, Segment, Image, Sidebar } from 'semantic-ui-react';
import { createMedia } from '@artsy/fresnel';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import supinie_solutions from '../supinie_solutions.png';
import 'semantic-ui-css/semantic.min.css';
import { Dashboard } from './Dashboard';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const SmallHeading = ({ mobile }) => (
  <Container text>
    <Header
      as="h1"
      content="Privacy Policy"
      style={{
        color: '#f46f22',
        fontSize: mobile ? '2em' : '3em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '2em',
      }}
    />
  </Container>
);

SmallHeading.propTypes = {
  mobile: PropTypes.bool,
};

class DesktopContainer extends React.Component {
  state = {};

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;
    return (
      <Media greaterThan="mobile">
        <InView onChange={this.toggleFixedMenu}>
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 200, padding: '1em 0em' }}
            vertical
          >
            <SmallHeading />
          </Segment>
        </InView>
        {children}
      </Media>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends React.Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;
    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable>
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 150, padding: '1em 0em' }}
              vertical
            >
              <SmallHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>

      </Media>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const Privacy = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width = {14}>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Supinie Solutions LTD understands that your privacy is important to you. We are committed to protecting the privacy of your personally identifiable information as you use this website. This Privacy Policy explains how we protect and use information that we gather from you. By using this website, you consent to the terms described in the most recent version of this Privacy Policy. You should also read our Terms of Use to understand the general rules about your use of this website, and any additional terms that may apply when you access particular services or materials on certain areas of this website. “We,” “our” means Supinie Solutions LTD and its affiliates. “You,” “your,” “visitor,” or “user” means the individual accessing this site.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                What is non-personal Information and how is it collected and used?
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Non-personal information is information that cannot identify you. If you visit this website to read information, such as information about one of our services, we use collect certain non-personal information about you from your computer’s web browser. We do this to better create a better user experience, and at no point do we collect or store this information.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                What is personal information and how is it collected?
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Personal information is information that identifies you as an individual, such as your email address. We collect personal information only in the form of your email address when you log in to our site using federated single sign-on via "Login with Amazon."
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                Are cookies or other technologies used to collect personal information?
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                No, we do not use cookies to track your activity on our website. We are committed to ensuring that your browsing experience is private and secure. We only collect personal information as explicitly stated above, and no additional data is gathered through cookies or similar technologies. We only use cookies to improve login experience via SSO, as is industry standard.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                How does Supinie Solutions LTD use personal information?
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Supinie Solutions LTD may keep and use the email address we collect from you to provide you with access to this website or other products or services, to respond to your requests, and to provide ongoing service and support.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                Does Supinie Solutions LTD share personal information with others?
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                We will not share your personal information collected from this website with an unrelated third party without your permission, except as otherwise provided in this Privacy Policy. In the ordinary course of business, we may share some personal information with companies that we hire to perform services or functions on our behalf. In all cases in which we share your personal information with a third party for the purpose of providing a service to us, we will not authorize them to keep, disclose, or use your information with others except for the purpose of providing the services we asked them to provide.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
We will not sell, exchange, or publish your personal information, except in conjunction with a corporate sale, merger, dissolution, or acquisition. If legally compelled, we may release your personal information in response to a court order, subpoena, search warrant, law, or regulation.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                How is personal information stored?
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                We have implemented generally accepted standards of technology and operational security to protect personally identifiable information from loss, misuse, alteration, or destruction. Only authorized personnel and third-party vendors have access to your personal information, and these employees and vendors are required to treat this information as confidential.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                How can a user access, change, and/or delete personal information?
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                You may access, correct, update, and/or delete any personally identifiable information that you submit to the website. To do so, please contact us at privacy@supinie.com.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                Changes
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Supinie Solutions LTD reserves the right to modify this statement at any time. Any changes to this Privacy Policy will be listed in this section, and if such changes are material, a notice will be included on the homepage of the website for a period of time. If you have any questions about privacy at any websites operated by Supinie Solutions LTD or about our website practices, please contact us at: privacy@supinie.com, or read more at https://www.supinie.com/privacy.txt.
            </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    </Segment>


    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='About' />
              <List link inverted>
                <List.Item as='a'>Sitemap</List.Item>
                <List.Item><a href="mailto:inquiries@supinie.mozmail.com?body=Supinie Solutions inquiry">Contact Us</a></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Services' />
              <List link inverted>
                <List.Item><a href="/our_products">Our Products</a></List.Item>
                <List.Item><a href="/security">Security</a></List.Item>
                <List.Item><a href="/data_policy">Data Policy</a></List.Item>
                <List.Item><a href="/privacy">Privacy</a></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted style={{ color: '#b9b5b2' }}>
                Supinie Solutions
              </Header>
              <p style={{ fontSize: '1em', color: '#b9b5b2' }}>
                Supinie Solutions LTD, 2023
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
);

export default Privacy;
