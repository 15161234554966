import React, { useState, useEffect } from 'react';
import Filters from '../Filters';
import FortnightDropdown from '../SelectRegionDate';
import SbCampaignList from './SbList';
import { getLastFortnight, fetchFromS3, callUserAPI } from '../Dev';
import { Grid, View, useTheme, Button } from '@aws-amplify/ui-react';
import { SidePopout } from '../SidePopout';

function SbPage({ company }) {
    const [date, setDate] = useState(getLastFortnight);
    const [region, setRegion] = useState("UNKNOWN");
    const [data, setData] = useState(undefined);
    const [displayedCampaigns, setDisplayedCampaigns] = useState(undefined);
    const [showAll, setShowAll] = useState(true);
    const [showFullNames, setShowFullNames] = useState(false);
    const [isPopoutOpen, setIsPopoutOpen] = useState(false);

    const handleOpenPopout = () => {
        setIsPopoutOpen(true);
    };

    const handleClosePopout = () => {
        setIsPopoutOpen(false);
    }

    useEffect(() => {
        if (company) {
            updateData(date, region);
        }
    }, [company, date, region]);

    useEffect(() => {
        if (displayedCampaigns !== undefined && data !== undefined) {
            if (displayedCampaigns.length === data.length) {
                setShowAll(true);
            } else {
                setShowAll(false);
            }
        }
    }, [displayedCampaigns]);

    const updateData = async (newDate, newRegion) => {
        try {
            if (newRegion !== "UNKNOWN") {
                setDisplayedCampaigns("LOADING");
                const newData = await fetchFromS3(newDate, newRegion, '/sponsored-brands', company);
                if (newData !== undefined) {
                    const jsonData = JSON.parse(newData);
                    setData(jsonData);
                } else {
                    setDisplayedCampaigns("LOADING");
                }
            } else {
                setData(undefined);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handleFiltersChange = (filteredData) => {
        setDisplayedCampaigns(filteredData);
    };

    return (
        <View>
            <View style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
                <Button onClick={handleOpenPopout}>✍️</Button>
            </View>
            <SidePopout 
                company={company}
                campaigns={Array.isArray(displayedCampaigns) ? displayedCampaigns.map(campaign => (
                    `${campaign.campaignName} - ${campaign.campaignId}`
                )) : [""]}
                dates={[date]}
                isOpen={isPopoutOpen}
                onClose={handleClosePopout}
            />
            <Grid
                templateColumns="1fr 4fr"
                columnGap="0.5rem"
            >
                <View rowSpan={2}>
                    <Filters data={data} onChange={setDisplayedCampaigns} toggleFullNames={setShowFullNames}/>
                </View>
                <View> 
                    <FortnightDropdown date={date} setDate={setDate} region={region} setRegion={setRegion} updateData={updateData} />
                </View>
                <View>
                    <SbCampaignList campaigns={displayedCampaigns} showAll={showAll} showFullNames={showFullNames} date={date}/>
                </View>
            </Grid>
        </View>
    );
}

export default SbPage;
