export const countries = [
  { code: 'IT', emoji: '🇮🇹' },
  // { code: 'SE', emoji: '🇸🇪' },
  { code: 'ES', emoji: '🇪🇸' },
  { code: 'DE', emoji: '🇩🇪' },
  // { code: 'PL', emoji: '🇵🇱' },
  { code: 'FR', emoji: '🇫🇷' },
  { code: 'CA', emoji: '🇨🇦' },
  { code: 'NL', emoji: '🇳🇱' },
  { code: 'UK', emoji: '🇬🇧' },
  // { code: 'MX', emoji: '🇲🇽' },
  { code: 'US', emoji: '🇺🇸' },
  { code: 'UNKNOWN', emoji: '❓' },
];
