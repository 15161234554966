// CampaignList.js
import React, { useState, useEffect } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Table, TableHead, TableBody, TableCell, TableRow, Loader, Link } from '@aws-amplify/ui-react';
// import './css/CampaignList.css';
import '../css/table.css';
import { fetchDifference } from '../Dev';


function SbCampaignList({ campaigns, showAll, showFullNames, date }) {
    const [_hoveredCampaignName, setHoveredCampaignName] = useState(null);
    const [sortColumn, setSortColumn] = useState(null);
    const [differences, setDifferences] = useState({});

    useEffect(() => {
        const calc_differences = async () => {
            if (displayedCampaigns !== undefined) {
                for (const campaign of campaigns) {
                    const new_difference = await fetchDifference(campaign.campaignId, date);
                    setDifferences((prevDifferences) => ({
                        ...prevDifferences,
                        [campaign.campaignId]: new_difference,
                    }));
                }
            }
        };

        calc_differences();
    }, [campaigns]);


    if (campaigns === undefined) {
        return (
            <div>
                <p>"Please choose a region"</p>
            </div>
        );
    } else if (campaigns === "LOADING") {
            return (
                <div>
                    <Loader
                        size="large"
                    />
                </div>
            );
    }

    const handleSort = (column) => {
        setSortColumn(sortColumn === column ? null : column);
    };

  const displayedCampaigns = campaigns
    .sort((a, b) => {
        if (sortColumn === 'cost') {
            return b.cost - a.cost;
        } else if (sortColumn === 'clicks') {
            return b.clicks - a.clicks;
        } else if (sortColumn === 'costPerClick') {
            const sortValueA =
                a.purchasesClicks === 0 ? a.cost : a.cost / a.clicks;
            const sortValueB =
                b.purchasesClicks === 0 ? b.cost : b.cost / b.clicks;
            return sortValueB - sortValueA;
        } else if (sortColumn === 'impressions') {
            return b.impressions - a.impressions;
        } else if (sortColumn === 'purchases') {
            return b.purchasesClicks - a.purchasesClicks;
        } else if (sortColumn === 'costPerPurchase') {
            const sortValueA =
                a.purchasesClicks === 0 ? a.cost : a.cost / a.purchasesClicks;
            const sortValueB =
                b.purchasesClicks === 0 ? b.cost : b.cost / b.purchasesClicks;
            return sortValueB - sortValueA;
        } else if (sortColumn === 'detailPageViewsClicks') {
            return b.detailPageViewsClicks - a.detailPageViewsClicks;
        } else if (sortColumn === 'addToCartClicks') {
            return b.addToCartClicks - a.addToCartClicks;
        } else if (sortColumn === 'sales') {
            return b.salesClicks - a.salesClicks;
        } else if (sortColumn === 'acos') {
            const sortValueA =
                a.salesClicks === 0 ? a.cost : a.cost / a.salesClicks;
            const sortValueB =
                b.salesClicks === 0 ? b.cost : b.cost / b.salesClicks;
            return sortValueB - sortValueA;
        } else if (sortColumn === 'conversion') {
            const conversionA = (a.clicks > 0 ? a.purchasesClicks / a.clicks : 0);
            const conversionB = (b.clicks > 0 ? b.purchasesClicks / b.clicks : 0);
            return conversionB - conversionA;
        } else if (sortColumn === 'status') {
            const statusOrder = ['PAUSED', 'ENABLED', 'ARCHIVED'];
            const statusValueA = statusOrder.indexOf(a.campaignStatus);
            const statusValueB = statusOrder.indexOf(b.campaignStatus);
            return statusValueB - statusValueA;
        } else {
            return null;
        }
    });

    const totalImpressions = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.impressions,
        0
    );

    const totalSpend = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.cost,
        0
    );

    const totalSales = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.salesClicks,
        0
    );

    const totalClicks = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.clicks,
        0
    );

    const avgCostPerClick = totalClicks > 0 ? (totalSpend / totalClicks).toFixed(2) : 0;

    const totalPurchases = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.purchasesClicks,
        0
    );

    const totalPurchasedCampaigns = displayedCampaigns.reduce(
        (total, campaign) => total + (campaign.purchasesClicks > 0 ? 1 : 0),
        0
    );

    const totalAddToCart = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.addToCartClicks,
        0
    );

    const totalPageView = displayedCampaigns.reduce(
        (total, campaign) => total + campaign.detailPageViewsClicks,
        0
    );

    const avgCostPerPurchase = (displayedCampaigns.reduce(
        (total, campaign) => total + (campaign.purchasesClicks > 0 ? campaign.cost / campaign.purchasesClicks : 0),
        0
    ) / totalPurchasedCampaigns).toFixed(2);

    const avgClickedAcos = (displayedCampaigns.reduce(
        (total, campaign) => total + (campaign.salesClicks > 0 ? campaign.cost / campaign.salesClicks : 0),
        0
    ) / totalPurchasedCampaigns).toFixed(2) * 100;

    const avgAcos = totalSales > 0 ? (totalSpend / totalSales).toFixed(2) * 100 : ">100";

    const avgConversion = (displayedCampaigns.reduce(
        (total, campaign) => total + (campaign.clicks > 0 ? campaign.purchasesClicks / campaign.clicks : 0),
        0
    ) / displayedCampaigns.length).toFixed(2) * 100;


    const renderDifference = (difference) => {
        if (difference === undefined) return null;
        const percentage = (difference * 100).toFixed(0);
        if (percentage > 0) {
            return <span style={{ color: 'green' }} >∆ {percentage}%</span>;
        } else if (percentage < 0) {
            return <span style={{ color: 'red' }}>∇ {percentage}%</span>;
        } else {
            return <span style={{ color: 'orange' }}>-</span>;
        }
    };

    const renderReverseDiff = (difference) => {
        if (difference === undefined) return null;
        const percentage = (difference * 100).toFixed(0);
        if (percentage < 0) {
            return <span style={{ color: 'green' }} >∇ {percentage}%</span>;
        } else if (percentage > 0) {
            return <span style={{ color: 'red' }}>∆ {percentage}%</span>;
        } else {
            return <span style={{ color: 'orange' }}>-</span>;
        }
    };


  return (
    <div>
      <div className="table-container">
      <div className="table-wrapper">
        <Table variation="striped" highlightOnHover={true} className="global-styling-table">
          <TableHead>
            <TableRow>
              <TableCell style={{ align: "left" }}>Campaign Name</TableCell>
              <TableCell>Campaign ID</TableCell>
              <TableCell onClick={() => handleSort('impressions')} title="Total number of ad impressions.">Impressions 🔽</TableCell>
              {showAll === false && (
                <>
                  <TableCell onClick={() => handleSort('detailPageViewsClicks')} title="Total number of detailed page views from an ad.">Page Views 🔽</TableCell>
                  <TableCell onClick={() => handleSort('clicks')} title="Total number of clicks on an ad.">Clicks 🔽</TableCell>
                  <TableCell onClick={() => handleSort('addToCartClicks')} title="Total number of added to cart events from an ad.">Added to Cart 🔽</TableCell>
                  <TableCell onClick={() => handleSort('purchases')} title="Number of attributed conversion events occurring within 1 day of an ad click.">Purchases 🔽</TableCell>
                  <TableCell onClick={() => handleSort('costPerClick')} title="Total cost divided by total number of clicks.">Spend Per Click 🔽</TableCell>
                  <TableCell onClick={() => handleSort('costPerPurchase')} title="Total cost divided by purchases. If there are no purchases, then shows cost.">Spend Per Purchase 🔽</TableCell>
                  <TableCell onClick={() => handleSort('acos')} title="Advertising cost of sale. Percentage value of advertising cost divided by advertising sales revenue.">ACOS 🔽</TableCell>
                  <TableCell onClick={() => handleSort('cost')} title="Total cost of ad clicks.">Spend 🔽</TableCell>
                  <TableCell onClick={() => handleSort('sales')} title="Total value of sales occurring within 1 day of an ad click.">Sales Revenue 🔽</TableCell>
                  <TableCell onClick={() => handleSort('conversion')} title="Total purchases occuring within 1 day of an ad click divided by total clicks.">Conversion % 🔽</TableCell>
                </>
              )}
              <TableCell onClick={() => handleSort('status')} title="Click to sort">Campaign Status</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow className="summary-row">
              <TableCell>Total no. campaigns: {displayedCampaigns.length}</TableCell>
              <TableCell></TableCell>
              <TableCell>Total: {totalImpressions}</TableCell>
              {showAll === false && (
                <>
                  <TableCell>Total: {totalPageView}</TableCell>
                  <TableCell>Total: {totalClicks}</TableCell>
                  <TableCell>Total: {totalAddToCart}</TableCell>
                  <TableCell>Total: {totalPurchases}</TableCell>
                  <TableCell>Avg: {avgCostPerClick}</TableCell>
                  <TableCell title="Only counting campaigns with purchases">Avg: {avgCostPerPurchase}</TableCell>
                  <TableCell title="Only counting campaigns with purchases">Avg: {avgClickedAcos.toFixed(2)}% Total: {avgAcos}%</TableCell>
                  <TableCell>Total: {totalSpend.toFixed(2)}</TableCell>
                  <TableCell>Total: {totalSales.toFixed(2)}</TableCell>
                  <TableCell>Avg: {avgConversion.toFixed(2)}</TableCell>
                </>
              )}
              <TableCell />
            </TableRow>
            {displayedCampaigns.map((campaign) => (
              <TableRow>
                <TableCell title={campaign.campaignName} onMouseEnter={() => setHoveredCampaignName(campaign.campaignName)} onMouseLeave={() => setHoveredCampaignName(null)} style={{ textAlign: 'left' }}>
                    {showFullNames === false ? <ReactRouterLink to={`/campaign/${campaign.campaignId}`} component={Link} className="my-link">{campaign.campaignName.split(' - ').slice(0, 3).join(' - ')}</ReactRouterLink> : <ReactRouterLink to={`/campaign/${campaign.campaignId}`} component={Link} className="my-link">{campaign.campaignName}</ReactRouterLink>}                </TableCell>
                <TableCell title="Campaign ID">
                  {<ReactRouterLink to={`/campaign/${campaign.campaignId}`} component={Link} className="my-link">{campaign.campaignId}</ReactRouterLink>}
                </TableCell>
                <TableCell title="Impressions">{campaign.impressions} {renderDifference(differences[campaign.campaignId]?.impressions)}</TableCell>
                {showAll === false && (
                  <>
                    <TableCell title="Page Views">{campaign.detailPageViewsClicks} {renderDifference(differences[campaign.campaignId]?.detailPageViewsClicks)}</TableCell>
                    <TableCell title="Clicks">{campaign.clicks} {renderDifference(differences[campaign.campaignId]?.clicks)}</TableCell>
                    <TableCell title="Added to Cart">{campaign.addToCartClicks} {renderDifference(differences[campaign.campaignId]?.addToCartClicks)}</TableCell>
                    <TableCell title="Purchases">{campaign.purchasesClicks} {renderDifference(differences[campaign.campaignId]?.purchasesClicks)}</TableCell>
                    <TableCell title="Spend Per Click">{(campaign.cost / campaign.clicks).toFixed(2)} {renderDifference(differences[campaign.campaignId]?.costPerClick)}</TableCell>
                    <TableCell title="Spend Per Purchase">
                      {campaign.purchasesClicks === 0
                        ? campaign.cost
                        : (campaign.cost / campaign.purchasesClicks).toFixed(2)} {renderDifference(differences[campaign.campaignId]?.spend_per_purchase)}
                    </TableCell>
                    <TableCell title="ACOS">
                      {campaign.salesClicks === 0
                        ? campaign.cost === 0
                            ? "0"
                            : ">100"
                        : ((campaign.cost / campaign.salesClicks) * 100).toFixed(2)}% {renderReverseDiff(differences[campaign.campaignId]?.acos)}
                    </TableCell>
                    <TableCell title="Spend">{campaign.cost} {renderReverseDiff(differences[campaign.campaignId]?.cost)}</TableCell>
                    <TableCell title="Sales Revenue">{campaign.salesClicks} {renderDifference(differences[campaign.campaignId]?.salesClicks)}</TableCell>
                    <TableCell title="Conversion %">{((campaign.purchasesClicks / campaign.clicks) * 100).toFixed(0)} {renderDifference(differences[campaign.campaignId]?.conversion)}</TableCell>
                  </>
                )}
                <TableCell title="Status">{campaign.campaignStatus}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      </div>
    </div>
  );
}

export default SbCampaignList;
