import React from 'react';
import { Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import './css/Flagged.css';


function Flagged() {
  const navigate = useNavigate();

return (
    <div className="flagged-container"> 
        <h2 style={{ textAlign: 'center' }}>View by Campaign Type</h2>
        <Button
            style={{ marginRight: '1em' }}
            onClick={() => navigate('/sp')}
            variation="primary"
            colorTheme="warning"
        >
            Sponsored Products
        </Button>
        <Button
            style={{ marginRight: '1em' }}
            onClick={() => navigate('/sb')}
            variation="primary"
            colorTheme="warning"
        >
            Sponsored Brands
        </Button>
        <Button
            style={{ marginRight: '1em' }}
            onClick={() => navigate('/sd')}
            variation="primary"
            colorTheme="warning"
        >
            Sponsored Display
        </Button>
    </div>
  );
}

export default Flagged;



      // <div style={{ display: 'flex', flexDirection: 'column' }}>
