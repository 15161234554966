import React, { useState, useEffect } from 'react';
import { Accordion, Autocomplete, CheckboxField, SliderField, View, HighlightMatch, Button } from '@aws-amplify/ui-react';
import './css/filters.css';
import { useLocation, useNavigate } from 'react-router-dom';

const renderOptions = (option, value) => {
    return <HighlightMatch query={value}>{option?.description}</HighlightMatch>;
};

const Filters = ({ data, onChange, toggleFullNames }) => {
    const [enabled_only, set_enabled_only] = useState(false);
    const [clicked_only, set_clicked_only] = useState(false);
    const [purchased_only, set_purchased_only] = useState(false);
    const [no_purchased_only, set_no_purchased_only] = useState(false);
    const [min_acos_value, set_min_acos_value] = useState(0);
    const [max_acos_value, set_max_acos_value] = useState(100);
    const [min_conversion_value, set_min_conversion_value] = useState(0);
    const [max_conversion_value, set_max_conversion_value] = useState(100);
    const [campaign_search, set_campaign_search] = useState('');
    const [options, set_options] = useState([]);
    const [checked, set_checked] = useState(true);

    const location = useLocation();
    const navigate = useNavigate();
    let buttons = [];
    if (location.pathname === '/sp') {
        buttons.push(["Spons. Brands", "/sb"], ["Spons. Display", "/sd"]);
    } else if (location.pathname === '/sd') {
        buttons.push(["Spons. Products", "/sp"], ["Spons. Brands", "/sb"]);
    } else if (location.pathname === '/sb') {
        buttons.push(["Spons. Products", "/sp"], ["Spons. Display", "/sd"]);
    }

    useEffect(() => {
        if (checked === true) {
            toggleFullNames(true);
        } else {
            toggleFullNames(false);
        }
    }, [checked]);

    const handleMinAcosValueChange = (newValue) => {
        if (newValue < max_acos_value) {
            set_min_acos_value(newValue);
        }
    }

    const handleMaxAcosValueChange = (newValue) => {
        if (newValue > min_acos_value) {
            set_max_acos_value(newValue);
        }
    }

    const handleMinConversionValueChange = (newValue) => {
        if (newValue < max_conversion_value) {
            set_min_conversion_value(newValue);
        }
    }

    const handleMaxConversionValueChange = (newValue) => {
        if (newValue > min_conversion_value) {
            set_max_conversion_value(newValue);
        }
    }

    const optionFilter = (option, value) => {
        return option?.description?.toLowerCase().includes(value.toLowerCase());
    };

    useEffect(() => {
        if (data !== undefined && data !== "LOADING") {
            const newOptions = data.map(campaign => ({
                id: campaign.campaignId.toString(),
                label: campaign.campaignName,
                description: `${campaign.campaignName} - (${campaign.campaignId})`
            }));
            set_options(newOptions)
        }
    }, [data]);

    useEffect(() => {
        if (data !== undefined && data !== "LOADING") {
            const display_campaigns = data.filter(campaign => {
                if (enabled_only && campaign.campaignStatus !== "ENABLED") {
                    return false;
                }

                if (clicked_only && campaign.clicks <= 0) {
                    return false;
                }

                if (purchased_only && (campaign.purchases1d <= 0 | campaign.purchasesClicks <= 0)) {
                    return false;
                }

                if (no_purchased_only && (campaign.purchases1d > 0 | campaign.purchasesClicks > 0)) {
                    return false;
                }

                if ((((campaign.spend / campaign.sales1d) * 100) < min_acos_value) | (((campaign.cost / campaign.salesClicks) * 100) < min_acos_value)) {
                    return false;
                }

                if (max_acos_value !== 100) {
                    if ((campaign.sales1d === 0) | (campaign.salesClicks === 0)) {
                        return false;
                    }
                    if ((((campaign.spend / campaign.sales1d) * 100) > max_acos_value) | (((campaign.cost / campaign.salesClicks) * 100) > max_acos_value)) {
                        return false;
                    }
                }

                if ((((campaign.purchases1d / campaign.clicks) * 100) < min_conversion_value) | (((campaign.purchasesClicks / campaign.clicks) * 100) < min_conversion_value)) {
                    return false;
                }

                if ((((campaign.purchases1d / campaign.clicks) * 100) > max_conversion_value) | (((campaign.purchasesClicks / campaign.clicks) * 100) > max_conversion_value)) {
                    return false;
                }

                if (campaign_search) {
                    const searchTerm = campaign_search.toLowerCase();
                    const campaignName = campaign.campaignName.toLowerCase();
                    const campaignId = campaign.campaignId.toString().toLowerCase();
                    const campaignSearchAgainst = campaignName + campaignId;
                    return campaignSearchAgainst.includes(searchTerm);
                }
                return true;
            });
            onChange(display_campaigns);
        }
    }, [data, enabled_only, clicked_only, purchased_only, min_acos_value, max_acos_value, min_conversion_value, max_conversion_value, campaign_search]);


    return (
        <View>
            <View style={{ marginBottom: '2em' }}>
                <Button
                    style={{ marginRight: '2.4em' }}
                    onClick={() => navigate('/dashboard')}
                    variation="primary"
                    colorTheme="info"
                >
                    Back
                </Button>
                <Button
                    style={{ marginRight: '0.4em' }}
                    onClick={() => navigate(buttons[0][1])}
                    variation="primary"
                    colorTheme="warning"
                >
                    {buttons[0][0]}
                </Button>
                <Button
                    style={{ marginRight: '0em' }}
                    onClick={() => navigate(buttons[1][1])}
                    variation="primary"
                    colorTheme="warning"
                >
                    {buttons[1][0]}
                </Button>
            </View>
            <Accordion.Container allowMultiple defaultValue={['Enabled', 'Clicks', 'Purchases', 'ACOS', 'Conversion', 'Search', 'Name']}>
                <Accordion.Item value="Enabled">
                    <Accordion.Trigger>
                        Enabled Only
                        <Accordion.Icon />
                    </Accordion.Trigger>
                    <Accordion.Content>
                        <CheckboxField
                            label="Show only enabled campaigns"
                            name="enabled"
                            value="yes"
                            checked={enabled_only}
                            onChange={(e) => set_enabled_only(e.target.checked)}
                        />
                    </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item value="Clicks">
                    <Accordion.Trigger>
                        Filter Clicks
                        <Accordion.Icon />
                    </Accordion.Trigger>
                    <Accordion.Content>
                        <CheckboxField
                            label="Show only clicked campaigns"
                            name="clicked"
                            value="yes"
                            checked={clicked_only}
                            onChange={(e) => set_clicked_only(e.target.checked)}
                        />
                    </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item value="Purchases">
                    <Accordion.Trigger>
                        Filter Purchases
                        <Accordion.Icon />
                    </Accordion.Trigger>
                    <Accordion.Content>
                        <CheckboxField
                            label="Show only purchased campaigns"
                            name="purchases"
                            value="yes"
                            checked={purchased_only}
                            onChange={(e) => set_purchased_only(e.target.checked)}
                        />
                        <CheckboxField
                            label="Show only unpurchased campaigns"
                            name="no_purchases"
                            value="yes"
                            checked={no_purchased_only}
                            onChange={(e) => set_no_purchased_only(e.target.checked)}
                        />
                    </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item value="ACOS">
                    <Accordion.Trigger>
                        Filter ACOS: {min_acos_value}-{max_acos_value}%
                        <Accordion.Icon />
                    </Accordion.Trigger>
                    <Accordion.Content> 
                        <SliderField
                            className="min-slider"
                            label="Min ACOS"
                            max={100}
                            value={min_acos_value}
                            onChange={handleMinAcosValueChange}
                            isValueHidden
                        />
                        <SliderField
                            className="max-slider"
                            label="Max ACOS"
                            max={100}
                            value={max_acos_value}
                            onChange={handleMaxAcosValueChange}
                            isValueHidden
                        />
                    </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item value="Conversion">
                    <Accordion.Trigger>
                        Filter Conversion: {min_conversion_value}-{max_conversion_value}%
                        <Accordion.Icon />
                    </Accordion.Trigger>
                    <Accordion.Content> 
                        <SliderField
                            className="min-slider"
                            label="Min Conversion"
                            max={100}
                            value={min_conversion_value}
                            onChange={handleMinConversionValueChange}
                            isValueHidden
                        />
                        <SliderField
                            className="max-slider"
                            label="Max Conversion"
                            max={100}
                            value={max_conversion_value}
                            onChange={handleMaxConversionValueChange}
                            isValueHidden
                        />
                    </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item value="Search">
                    <Accordion.Trigger>
                        Search Campaigns
                        <Accordion.Icon />
                    </Accordion.Trigger>
                    <Accordion.Content>
                        <Autocomplete
                            label="search"
                            options={options}
                            optionFilter={optionFilter}
                            placeholder="Search by campaign name/ID..."
                            value={campaign_search}
                            onChange={(event) => set_campaign_search(event.target.value)}
                            onClear={() => set_campaign_search('')}
                            onSelect={(option) => {
                                set_campaign_search(option?.label);
                            }}
                            renderOption={renderOptions}
                        />
                    </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item value="Name">
                    <Accordion.Trigger>
                        Campaign Name
                        <Accordion.Icon />
                    </Accordion.Trigger>
                    <Accordion.Content>
                        <CheckboxField
                            label="Show full campaign names"
                            name="name"
                            checked={checked}
                            onChange={(e) => set_checked(e.target.checked)}
                        />
                    </Accordion.Content>
                </Accordion.Item>
            </Accordion.Container> 
        </View>
    );
}

export default Filters;
