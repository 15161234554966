import React from 'react';
import { Container, Header, Grid, List, Segment, Image, Sidebar } from 'semantic-ui-react';
import { createMedia } from '@artsy/fresnel';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import supinie_solutions from '../supinie_solutions.png';
import 'semantic-ui-css/semantic.min.css';


const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as="h1"
      content="Kaleidos.ai"
      style={{
        color: '#f46f22',
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as="h2"
      content="Building bespoke advertising dashboards."
      inverted
      style={{
        color: '#b9b5b2',
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

class DesktopContainer extends React.Component {
  state = {};

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;
    return (
      <Media greaterThan="mobile">
        <InView onChange={this.toggleFixedMenu}>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 700, padding: '1em 0em' }}
                vertical
            >
            <HomepageHeading />
            </Segment>
        </InView>
        {children}
      </Media>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends React.Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;
    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable>
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 350, padding: '1em 0em' }}
              vertical
            >
              <HomepageHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>

      </Media>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const Home = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
              We Help Companies Advertise Effectively
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                We aim to provide a solution that will provide granular, quantifiable data in an easy to read,
                navigate, and understand format in order to reliably track brand performance against advertising spending,
                gauge advertising effectiveness (at both a general and individual level), and provide a means to heuristically
                approximate advertising campaign effectiveness at a given point in time.
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image bordered rounded size='large' src={supinie_solutions} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '0em' }} vertical>
      <Grid celled='internally' columns='equal' stackable>
        <Grid.Row textAlign='center'>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em', color: '#b9b5b2' }}>
              Global/Regional Homepage Metrics
            </Header>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em', color: '#b9b5b2' }}>
              Product Page Metrics
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
          Easily display advertising metrics from a top-down, easy to understand format
        </Header>
        <p style={{ fontSize: '1.33em', color: '#b9b5b2' }}>
            Display key metrics from a dashboard perspective that allows easy identification of problem areas,
            and allows you to focus in on areas of difficulty or success.
        </p>
      </Container>
    </Segment>

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='About' />
              <List link inverted>
                <List.Item as='a'>Sitemap</List.Item>
                <List.Item><a href="mailto:inquiries@supinie.mozmail.com?body=Supinie Solutions inquiry">Contact Us</a></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Services' />
              <List link inverted>
                <List.Item><a href="/our_products">Our Products</a></List.Item>
                <List.Item><a href="/security">Security</a></List.Item>
                <List.Item><a href="/data_policy">Data Policy</a></List.Item>
                <List.Item><a href="/privacy">Privacy</a></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted style={{ color: '#b9b5b2' }}>
                Supinie Solutions
              </Header>
              <p style={{ fontSize: '1em', color: '#b9b5b2' }}>
                Supinie Solutions LTD, 2023
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
);

export default Home;
