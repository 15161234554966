import React from 'react';
import './css/NotFound.css';

function NotFound() {
  return (
    <div>
      <h1>404</h1>
      <p>That page could not be found.</p>
      <a className="button" href="/">
        <i className="icon-home"></i> Home
      </a>
    </div>
  );
}

export default NotFound;
