import React, { useState, useEffect } from 'react';
import { CheckboxField, Button, SwitchField, Flex, View, Grid } from '@aws-amplify/ui-react';
import { countries } from './CountryMap';
import { fortnightsArray } from './Fortnights';
import './css/DownloadPage.css';

export const DownloadPage = () => {
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedFortnights, setSelectedFortnights] = useState([]);
    const [reportTypes, setReportTypes] = useState([]);
    const [allFortnightsSelected, setAllFortnightsSelected] = useState(false);
    const [hasError, setHasError] = useState(false);

    const handleCheckboxChange = (event) => {
        const countryCode = event.target.name;
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedCountries((prevSelected) => [...prevSelected, countryCode]);
        } else {
            setSelectedCountries((prevSelected) =>
                prevSelected.filter((code) => code !== countryCode)
            );
        }

        setHasError(false);
    };

    const handleFortnightChange = (event) => {
        const fortnight = event.target.name;
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedFortnights((prevSelected) => [...prevSelected, fortnight]);
        } else {
            setSelectedFortnights((prevSelected) =>
                prevSelected.filter((code) => code !== fortnight)
            );
        }

        setHasError(false);
    };

    const handleSelectAllCountries = () => {
        setSelectedCountries((prevSelected) =>
            prevSelected.length === (countries.length - 1) ? [] : countries.slice(0, -1).map((country) => country.code)
        );
    };

    const handleSelectAllFortnights = () => {
        const last10Fortnights = fortnightsArray.slice(-10).map((fortnight) => fortnight.value);
        setSelectedFortnights((prevSelected) =>
            allFortnightsSelected
                ? []
                : last10Fortnights.filter((fortnight) => {
                    return !(reportTypes.includes('sb') || reportTypes.includes('sd')) ||
                        new Date(fortnight) >= new Date('2023-09-24');
                })        
        );
        allFortnightsSelected ? setAllFortnightsSelected(false) : setAllFortnightsSelected(true)
    };

    const handleSelectLastFortnight = () => {
        const lastFortnightValue = fortnightsArray.slice(-1)[0].value;

        setSelectedFortnights((prevSelected) =>
            prevSelected.includes(lastFortnightValue)
                ? prevSelected.filter((code) => code !== lastFortnightValue)
                : [...prevSelected, lastFortnightValue]
        );
    };

    const handleReportTypeChange = (reportType) => {
        setReportTypes((prevReportTypes) =>
            prevReportTypes.includes(reportType)
                ? prevReportTypes.filter((type) => type !== reportType)
                : [...prevReportTypes, reportType]
        );

        if (reportType === 'sb' || reportType === 'sd') {
        setSelectedFortnights((prevSelected) =>
            prevSelected.filter((fortnight) => {
                const isDisabled =
                    (reportType === 'sb' || reportType === 'sd') &&
                    new Date(fortnight) < new Date('2023-09-24');
                return !isDisabled;
            })
        );
    }
    };


    useEffect(() => {
        setHasError(false);
    }, [selectedCountries, selectedFortnights, reportTypes]);

    const onSubmit = (event) => {
        event.preventDefault();
        if (selectedCountries.length === 0 || selectedFortnights.length === 0 || reportTypes.length === 0) {
            setHasError(true);
        } else {
            setHasError(false);
            const countries_message = `${selectedCountries.join(', ')}`;
            const fortnights_message = `${selectedFortnights.join(', ')}`;
            const reports_message = `${reportTypes.join(', ')}`;
            const message = `${countries_message}\n${fortnights_message}\n${reports_message}`;
            alert("Download currently unavailable.");
        }
    };
    return (
        <form onSubmit={onSubmit}>
            <Grid
                columnGap="0.5rem"
                rowGap="0.5rem"
                templateColumns="1fr 1fr 1fr"
                templateRows="1fr 6fr"
            >
                <SwitchField
                    label="Sponsored Products"
                    onChange={() => handleReportTypeChange('sp')}
                    className="amplify-switch__label"
                />
                <SwitchField
                    label="Sponsored Brands"
                    onChange={() => handleReportTypeChange('sb')}
                    className="amplify-switch__label"
                />
                <SwitchField
                    label="Sponsored Display"
                    onChange={() => handleReportTypeChange('sd')}
                    className="amplify-switch__label"
                />
                <Grid
                    columnStart="1"
                    columnEnd="-1"
                    columnGap="0.5rem"
                    rowGap="0.5rem"
                    templateColumns="1fr 1fr"
                    templateRows="6fr 1fr"
                >
                    <Flex direction="column" gap="0">
                        <CheckboxField
                            label="All countries"
                            onChange={handleSelectAllCountries}
                            checked={selectedCountries.length === (countries.length - 1)}
                            size="large"
                        />
                        <View paddingLeft="25px">
                            {countries.slice(0, -1).map((country) => (
                                <CheckboxField
                                    key={country.code}
                                    label={country.emoji}
                                    name={country.code}
                                    value="yes"
                                    onChange={handleCheckboxChange}
                                    checked={selectedCountries.includes(country.code)}
                                    size="large"
                                />
                            ))}
                        </View>
                    </Flex>
                    <Flex direction="column" gap="0">
                        <CheckboxField
                            label="Most recent fortnight"
                            onChange={handleSelectLastFortnight}
                            checked={selectedFortnights.includes(fortnightsArray.slice(-1)[0].value)}
                            size="large"
                        />
                        <CheckboxField
                            label="All fortnights"
                            onChange={handleSelectAllFortnights}
                            checked={allFortnightsSelected}
                            size="large"
                        />
                        <View paddingLeft="25px">
                            {fortnightsArray.slice(-10).map((fortnight) => (
                                <CheckboxField
                                    key={fortnight.value}
                                    label={fortnight.label}
                                    name={fortnight.value}
                                    value="yes"
                                    onChange={handleFortnightChange}
                                    checked={selectedFortnights.includes(fortnight.value)}
                                    size="large"
                                    isDisabled={
                                        (reportTypes.includes("sb") || reportTypes.includes("sd")) &&
                                        new Date(fortnight.value) < new Date('2023-09-24')
                                    }
                                />
                            ))}
                        </View>
                    </Flex>

                    <Button type="submit" variation="primary" colorTheme="warning" columnStart="1" columnEnd="-1">
                        Submit
                    </Button>
                    {hasError && (
                        <p style={{ color: 'red', marginTop: '8px', gridColumn: '1 / -1'}}>
                            Please select at least one country and one fortnight.
                        </p>
                    )}
                </Grid>
            </Grid>
        </form>
    );
}
