// Generate the last 10 fortnights
const fortnightsArray = [];

const epochDate = new Date("2023-7-30");
const today = new Date();
const old_date = new Date(epochDate);

while (old_date < today) {
    const yyyy_start = old_date.getFullYear();
    const mm_start = String(old_date.getMonth() + 1).padStart(2, '0');
    const dd_start = String(old_date.getDate()).padStart(2, '0');

    old_date.setDate(old_date.getDate() + 14);

    if (old_date < today) {
    
        const yyyy_end = old_date.getFullYear();
        const mm_end = String(old_date.getMonth() + 1).padStart(2, '0');
        const dd_end = String(old_date.getDate()).padStart(2, '0');

        fortnightsArray.push({
            value: `${yyyy_end}-${mm_end}-${dd_end}`,
            label: `${yyyy_start}-${mm_start}-${dd_start} to ${yyyy_end}-${mm_end}-${dd_end}`,
        });
    }
}


export { fortnightsArray };
