import React from 'react';
import { Container, Header, Grid, List, Segment, Image, Sidebar } from 'semantic-ui-react';
import { createMedia } from '@artsy/fresnel';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import supinie_solutions from '../supinie_solutions.png';
import 'semantic-ui-css/semantic.min.css';
import { Dashboard } from './Dashboard';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const SmallHeading = ({ mobile }) => (
  <Container text>
    <Header
      as="h1"
      content="Data Protection"
      style={{
        color: '#f46f22',
        fontSize: mobile ? '2em' : '3em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '2em',
      }}
    />
  </Container>
);

SmallHeading.propTypes = {
  mobile: PropTypes.bool,
};

class DesktopContainer extends React.Component {
  state = {};

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;
    return (
      <Media greaterThan="mobile">
        <InView onChange={this.toggleFixedMenu}>
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 200, padding: '0em' }}
            vertical
          >
            <SmallHeading />
          </Segment>
        </InView>
        {children}
      </Media>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends React.Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;
    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable>
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 150, padding: '0em' }}
              vertical
            >
              <SmallHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>

      </Media>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const Security = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width = {14}>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Supinie Solutions LTD utilizes state-of-the-art technologies to ensure the secure storage and handling of user data. Our priority is to maintain strict security measures to safeguard each user's data and prevent any unauthorized access or public exposure within the app.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                Our commitment to Data Privacy
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                We strictly refrain from using Personally Identifiable Information (PII) of customers for purposes other than merchant-fulfilled shipping or to fulfill legal obligations, such as tax and regulatory requirements, and providing our services.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                We do not engage in product marketing or manipulate reviews by leveraging data obtained through Amazon's APIs or any external (non-Amazon) data services to target Amazon customers.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                We do not aggregate data across different users' businesses or customers obtained through the Amazon Services API for the purpose of providing or selling it to any parties, including competing users.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                We do not utilize insights about Amazon's business for our own business purposes and refrain from sharing or publishing such insights.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Information, whether individually labeled or aggregated, is not disclosed to other application users, affiliated entities, or any external parties.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Transparency is a core value for us, and we consistently provide authorized users with clear and comprehensive information about the data we share, the parties involved, and the purposes for which it is shared.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                Data Protection Policy Statement
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                At Supinie Solutions LTD, we are committed to ensuring the utmost protection and privacy of data, including information received, stored, used, transferred, and disposed of through the Amazon Services API. We understand the significance of complying with the Data Protection Policy (DPP), which supplements the Amazon Services API Developer Agreement and the Acceptable Use Policy. Non-compliance may result in the suspension or termination of our access to the Amazon Services API.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                Comprehensive Security Measures
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                To uphold industry-leading security practices, we maintain physical, administrative, and technical safeguards to protect the security and confidentiality of data accessed, collected, used, stored, or transmitted by our systems. Our measures aim to defend against known or anticipated threats, ensuring data integrity and safeguarding against accidental loss, alteration, disclosure, or any other unlawful processing. To meet these requirements, we commit to the following:
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Network Protection: We implement network protection controls such as firewalls, network access control lists, network segmentation, anti-virus, and anti-malware software on end-user devices. Public access is limited to authorized users only, and regular data protection and IT security training is provided to all individuals with system access.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Access Management: We establish a formal user access registration process, ensuring that each person with computer access to data is assigned a unique ID. We prohibit the creation and use of generic, shared, or default login credentials or user accounts, and prevent the sharing of user accounts. Our baselining mechanisms guarantee that only necessary user accounts can access data. We prohibit employees and contractors from storing data on personal devices. We enforce "account lockout" measures, detecting anomalous usage patterns and login attempts, and disabling accounts with access to data. We conduct quarterly reviews of people and services with access to data, promptly disabling and removing access within 24 hours for terminated employees.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Least Privilege Principle: We implement fine-grained access control mechanisms, granting access to data on a "need-to-know" basis, following the principle of least privilege.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Credential Management: We establish minimum password requirements for personnel and systems accessing data, including a minimum password length of twelve characters, prohibition of using any part of the user's name, and a mix of upper-case letters, lower-case letters, numbers, and special characters. We enforce a minimum password age of one day and a maximum password expiration of 365 days for all users. Multi-Factor Authentication (MFA) is mandatory for all user accounts. We ensure that Amazon-provided API keys are encrypted, and access to them is restricted to authorized employees.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Encryption in Transit: We encrypt all data in transit using secure protocols such as TLS 1.2+, SFTP, and SSH-2. This security control applies to all internal and external endpoints. In situations where channel encryption terminates in untrusted multi-tenant hardware, we implement data message-level encryption.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Risk Management and Incident Response Plan: We conduct an annual risk assessment and management process reviewed by senior management. This includes evaluating potential threats, vulnerabilities, and associated risks. We maintain a plan and/or runbook to detect and handle security incidents, defining incident response roles, procedures, and escalation paths. We regularly review and verify the plan, notifying Amazon within 24 hours of detecting a security incident. We investigate each incident, documenting the incident description, remediation actions, and implemented controls. We maintain the chain of custody for all evidence or records collected, making them available to Amazon upon request. We refrain from representing or speaking on behalf of Amazon to regulatory authorities or customers without explicit written request from Amazon.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Request for Deletion: We promptly and securely delete data upon receiving Amazon's deletion request within 30 days. Secure deletion follows industry-standard sanitization.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Data Attribution: We ensure that all data within our databases is stored with proper attribution by either maintaining a separate database or implementing a mechanism to tag and identify the origin of the data.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                Additional Security Requirements for Personally Identifiable Information (PII)
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                To safeguard Personally Identifiable Information (PII), Supinie Solutions LTD adheres to the following additional security requirements. PII is granted to us for specific tax and merchant-fulfilled shipping purposes, based on necessity. If any PII is present in our Amazon Services API or combined with non-PII, we ensure that our entire data store complies with the following requirements:
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Data Retention: We retain PII for a maximum of 30 days after order delivery, strictly for fulfilling orders, calculating and remitting taxes, producing tax invoices, meeting legal requirements, and complying with tax or regulatory obligations. If retention beyond 30 days is required by law for legal compliance purposes, we securely store the data with appropriate encryption measures as outlined in sections 1.5 ("Encryption in Transit") and 2.4 ("Encryption at Rest").
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Data Governance: We establish and adhere to a comprehensive privacy and data handling policy for our applications or services, governing the appropriate conduct and technical controls for managing and protecting information assets. We maintain a detailed record of data processing activities, including the collection, processing, storage, use, sharing, and disposal of PII, ensuring accountability and compliance with regulations. We have robust processes in place to detect and comply with privacy and security laws and regulations, backed by documented evidence of our compliance. Our privacy policy encompasses customer consent and data rights, including access, rectification, erasure, and the cessation of sharing/processing information in accordance with applicable data privacy regulations. We have implemented technical and organizational measures to assist authorized users with data subject access requests, and our employees who handle PII are bound by contractual provisions to maintain its confidentiality.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Asset Management: We maintain a standardized configuration for our information system and maintain an inventory of software and physical assets (e.g., computers, mobile devices) with access to PII, ensuring regular updates. We strictly adhere to the policy requirements for physical assets involved in storing, processing, or handling PII. PII is never stored on removable media, personal devices, or unsecured public cloud applications unless properly encrypted using robust encryption methods. We securely dispose of any printed documents containing PII, and data loss prevention controls are in place to monitor and prevent unauthorized data movement.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Encryption at Rest: All PII at rest within our systems is encrypted using industry-standard encryption methods, such as AES-128 or RSA with a key size of 2048 bits or higher. Access to the cryptographic materials and capabilities used for encrypting PII is strictly limited to our authorized processes and services.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Secure Coding Practices: We follow secure coding practices by ensuring that sensitive credentials, including encryption keys, secret access keys, or passwords, are not hardcoded in our code. We do not expose sensitive credentials in public code repositories. Additionally, we maintain separate test and production environments to minimize risks.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Logging and Monitoring: We maintain comprehensive logs to detect security-related events within our applications and systems, including access attempts, data changes, and system errors. Logging mechanisms are implemented across all channels providing access to information. We regularly review logs either in real-time or on a bi-weekly basis, and strict access controls are enforced to prevent unauthorized access or tampering. PII is not included in logs unless necessary for legal requirements. We retain logs for at least 90 days, unless otherwise mandated by law. We have implemented monitoring alarms and processes to detect any unauthorized extraction or access to information beyond its protected boundaries. We conduct thorough investigations documented in our Incident Response Plan when monitoring alarms are triggered.
            </p>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Vulnerability Management: We have a robust plan in place to detect and remediate vulnerabilities within our systems. We prioritize protecting physical hardware containing PII by conducting regular vulnerability scans and promptly addressing any identified vulnerabilities. Our vulnerability scanning is conducted at least every 180 days, and penetration tests are performed at least every 365 days. We also scan code for vulnerabilities before each release. Additionally, we control changes to storage hardware through thorough testing, verification, approval, and restricted access. In the event of a physical or technical incident, we have established procedures and plans to restore availability and access to PII promptly.
            </p>
            <Header as='h3' style={{ fontSize: '2em', color: '#f46f22' }}>
                Audit and Assessment
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight: 'Normal', color: '#b9b5b2' }}>
                Supinie Solutions LTD maintains all necessary books and records to verify our compliance with the Acceptable Use Policy, Data Protection Policy, and Amazon Services API Developer Agreement throughout the agreement period and for an additional 12 months. Upon Amazon's request, we provide written certification of our compliance with these policies. We fully cooperate with Amazon or their designated auditor during audits, assessments, or inspections of our books, records, facilities, operations, and security systems involved in the retrieval, storage, or processing of information related to our application. We treat all nonpublic information disclosed during these audits as confidential, and we ensure its protection. In the case of any deficiencies, breaches, or failures to comply with the terms, conditions, or policies, we take prompt action at our expense to rectify the issues within the agreed-upon timeframe. We provide Amazon with requested evidence of remediation and obtain their written approval before closing the audit.
            </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
    </Segment>


    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='About' />
              <List link inverted>
                <List.Item as='a'>Sitemap</List.Item>
                <List.Item><a href="mailto:inquiries@supinie.mozmail.com?body=Supinie Solutions inquiry">Contact Us</a></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Services' />
              <List link inverted>
                <List.Item><a href="/our_products">Our Products</a></List.Item>
                <List.Item><a href="/security">Security</a></List.Item>
                <List.Item><a href="/data_policy">Data Policy</a></List.Item>
                <List.Item><a href="/privacy">Privacy</a></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted style={{ color: '#b9b5b2' }}>
                Supinie Solutions
              </Header>
              <p style={{ fontSize: '1em', color: '#b9b5b2' }}>
                Supinie Solutions LTD, 2023
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
);

export default Security;
