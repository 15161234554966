//App.js
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { CampaignPage } from './components/Campaign';
import { Dashboard } from './components/Dashboard';
import { Organic} from './components/Organic';
import { RequireAuth } from './RequireAuth';
import { Login } from './components/Login';
import Home from './components/Home';
import OurProducts from './components/OurProducts';
import Privacy from './components/Privacy';
import DataPolicy from './components/DataPolicy';
import Security from './components/Security';
import { Layout } from './components/Layout';
import NotFound from './components/NotFound';
import UnderConstruction from './components/UnderConstruction';
import Authorisation from './components/Authorisation';
import Flagged from './components/Flagged';
import { getLastFortnight, fetchFromS3, callUserAPI } from './components/Dev';
import FortnightDropdown from './components/SelectRegionDate';
import { DownloadPage } from './components/DownloadPage';
import SpPage from './components/SponsoredProducts/SponsoredProductsPage';
import SbPage from './components/SponsoredBrands/SponsoredBrandsPage';
import SdPage from './components/SponsoredDisplay/SponsoredDisplayPage';
import { ViewNotes } from './components/ViewNotes';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import './App.css';

function MyRoutes() {
    const [company, setCompany] = useState(undefined);
    const [companyList, setCompanyList] = useState(undefined);

    useEffect(() => {
        callUserAPI().then(
            newData => {
                setCompany(newData[0]);
                setCompanyList(newData);
            },
            error => console.log(error)
        );
    }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout company={company} companyList={companyList} setCompany={setCompany} setCompanyList={setCompanyList}/>}>
          <Route index element={<Home />} />
          <Route path="our_products" element={<OurProducts />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="security" element={<Security />} />
          <Route path="data_policy" element={<DataPolicy />} />
          <Route
            path="action_library"
            element={
                <RequireAuth>
                    <ViewNotes company={company}/>
                </RequireAuth>
            }
          />
          <Route
            path="download"
            element={
                <RequireAuth>
                    <DownloadPage/>
                </RequireAuth>
            }
          />
          <Route
            path="dashboard"
            element={
                <RequireAuth>
                <div className="dashboard-container">
                    <Flagged/>
                    <Dashboard company={company}/>
                </div>
                </RequireAuth>
            }
          />
          <Route
            path="organic"
            element={
                <RequireAuth>
                    <Organic company={company}/>
                </RequireAuth>
            }
          />
          <Route
            path="/sp"
            element={
                <RequireAuth>
                    <SpPage company={company}/>
                </RequireAuth>
            }
          />
          <Route
            path="/sb"
            element={
                <RequireAuth>
                    <SbPage company={company}/>
                </RequireAuth>
            }
          />
          <Route
            path="/sd"
            element={
                <RequireAuth>
                    <SdPage company={company}/>
                </RequireAuth>
            }
          />
          <Route
            path="/campaign/:campaignId"
            element={
                <RequireAuth>
                    <CampaignPage company={company}/>
                </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/authorisation" element={<Authorisation />} />
      </Routes>
    </BrowserRouter>
  );
}

function App() {
    return (
        <Authenticator.Provider>
            <MyRoutes />
        </Authenticator.Provider>
    );
}

export default App;
