import React from 'react';
import { simpleFaker } from '@faker-js/faker';

function fakeArray(length, minimum, maximum) {
    return Array.from({ length }, () => simpleFaker.number.int({ min: minimum, max: maximum }));
}

export function fakeDashboard() {
    const data = {
        company: "ACME Corporation",
    data: {
      sd: {
        EU: {
          clicks: fakeArray(12, 1, 500),
          impressions: fakeArray(12, 10000, 200000),
          cost: fakeArray(12, 1, 500),
          purchases: fakeArray(12, 0, 20),
          sales: fakeArray(12, 0, 500),
        },
        US: {
          clicks: fakeArray(12, 1, 500),
          impressions: fakeArray(12, 10000, 200000),
          cost: fakeArray(12, 1, 500),
          purchases: fakeArray(12, 0, 20),
          sales: fakeArray(12, 0, 500),
        },
        UK: {
          clicks: fakeArray(12, 1, 500),
          impressions: fakeArray(12, 10000, 200000),
          cost: fakeArray(12, 1, 500),
          purchases: fakeArray(12, 0, 20),
          sales: fakeArray(12, 0, 500),
        }
      },
      total: {
        EU: {
          clicks: fakeArray(12, 1000, 10000),
          impressions: fakeArray(12, 100000, 1000000),
          cost: fakeArray(12, 100, 1000),
          purchases: fakeArray(12, 10, 100),
          sales: fakeArray(12, 1000, 10000),
        },
        US: {
          clicks: fakeArray(12, 1000, 10000),
          impressions: fakeArray(12, 100000, 1000000),
          cost: fakeArray(12, 100, 1000),
          purchases: fakeArray(12, 10, 100),
          sales: fakeArray(12, 1000, 10000),
        },
        UK: {
          clicks: fakeArray(12, 1000, 10000),
          impressions: fakeArray(12, 100000, 1000000),
          cost: fakeArray(12, 100, 1000),
          purchases: fakeArray(12, 10, 100),
          sales: fakeArray(12, 1000, 10000),
        }
      },
      sp: {
        EU: {
          clicks: fakeArray(12, 500, 5000),
          impressions: fakeArray(12, 50000, 500000),
          cost: fakeArray(12, 50, 500),
          purchases: fakeArray(12, 5, 50),
          sales: fakeArray(12, 500, 5000),
        },
        US: {
          clicks: fakeArray(12, 500, 5000),
          impressions: fakeArray(12, 50000, 500000),
          cost: fakeArray(12, 50, 500),
          purchases: fakeArray(12, 5, 50),
          sales: fakeArray(12, 500, 5000),
        },
        UK: {
          clicks: fakeArray(12, 500, 5000),
          impressions: fakeArray(12, 50000, 500000),
          cost: fakeArray(12, 50, 500),
          purchases: fakeArray(12, 5, 50),
          sales: fakeArray(12, 500, 5000),
        }
      },
      sb: {
        EU: {
          clicks: fakeArray(12, 200, 2000),
          impressions: fakeArray(12, 20000, 200000),
          cost: fakeArray(12, 20, 200),
          purchases: fakeArray(12, 2, 20),
          sales: fakeArray(12, 200, 2000),
        },
        US: {
          clicks: fakeArray(12, 200, 2000),
          impressions: fakeArray(12, 20000, 200000),
          cost: fakeArray(12, 20, 200),
          purchases: fakeArray(12, 2, 20),
          sales: fakeArray(12, 200, 2000),
        },
        UK: {
          clicks: fakeArray(12, 200, 2000),
          impressions: fakeArray(12, 20000, 200000),
          cost: fakeArray(12, 20, 200),
          purchases: fakeArray(12, 2, 20),
          sales: fakeArray(12, 200, 2000),
        }
      }
    },
    campaign_count: {
      past_sp: {
        DE: simpleFaker.number.int(100),
        IT: simpleFaker.number.int(100),
        FR: simpleFaker.number.int(100),
        US: simpleFaker.number.int(100),
        UK: simpleFaker.number.int(100),
        ES: simpleFaker.number.int(100)
      },
      sd: {
        DE: simpleFaker.number.int(100),
        IT: simpleFaker.number.int(100),
        FR: simpleFaker.number.int(100),
        US: simpleFaker.number.int(100),
        UK: simpleFaker.number.int(100),
        ES: simpleFaker.number.int(100)
      },
      total: {
        DE: simpleFaker.number.int(100),
        IT: simpleFaker.number.int(100),
        FR: simpleFaker.number.int(100),
        US: simpleFaker.number.int(100),
        UK: simpleFaker.number.int(100),
        ES: simpleFaker.number.int(100)
      },
      past_sd: {
        DE: simpleFaker.number.int(100),
        IT: simpleFaker.number.int(100),
        FR: simpleFaker.number.int(100),
        US: simpleFaker.number.int(100),
        UK: simpleFaker.number.int(100),
        ES: simpleFaker.number.int(100)
      },
      past_sb: {
        DE: simpleFaker.number.int(100),
        IT: simpleFaker.number.int(100),
        FR: simpleFaker.number.int(100),
        US: simpleFaker.number.int(100),
        UK: simpleFaker.number.int(100),
        ES: simpleFaker.number.int(100)
      },
      past_total: {
        DE: simpleFaker.number.int(100),
        IT: simpleFaker.number.int(100),
        FR: simpleFaker.number.int(100),
        US: simpleFaker.number.int(100),
        UK: simpleFaker.number.int(100),
        ES: simpleFaker.number.int(100)
      },
      sp: {
        DE: simpleFaker.number.int(100),
        IT: simpleFaker.number.int(100),
        FR: simpleFaker.number.int(100),
        US: simpleFaker.number.int(100),
        UK: simpleFaker.number.int(100),
        ES: simpleFaker.number.int(100)
      },
      sb: {
        DE: simpleFaker.number.int(100),
        IT: simpleFaker.number.int(100),
        FR: simpleFaker.number.int(100),
        US: simpleFaker.number.int(100),
        UK: simpleFaker.number.int(100),
        ES: simpleFaker.number.int(100)
      }
    }
  };

  return data;
}

