/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodoSecure = /* GraphQL */ `
  mutation CreateTodoSecure($input: createTodoInput) {
    createTodoSecure(input: $input) {
      id
      text
      group
      owner
      author
      campaign
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
