import React from 'react';
import { useSearchParams } from "react-router-dom"


function Authorisation() {
    const [queryParameters] = useSearchParams();

    return (
        <div>
            <p>Code: {queryParameters.get("code")}</p>
        </div>
    );
}

export default Authorisation;
