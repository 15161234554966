import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import { Line, Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Grid, Button, ButtonGroup, Heading, SwitchField } from '@aws-amplify/ui-react';

import { fetchSummary, getFortnightPeriods, getLastFortnight } from './Dev';

import './css/dashboard.css';


const Dashboard = ({ company }) => {
    const [bigChartData, setbigChartData] = React.useState("total");
    const [summaryData, setSummaryData] = useState(undefined);
    const [convToggle, setConvToggle] = useState(false);
    const [cpcToggle, setCpcToggle] = useState(false);
    const lastFortnight = getLastFortnight();
    const fortnights = getFortnightPeriods(lastFortnight);

    useEffect(() => {
        const getNewSummary = async () => {
            try {
                const newSummary = await fetchSummary(company);
                setSummaryData(newSummary);
            } catch (error) {
                console.error('Error fetching summary:', error);
            }
        };

        getNewSummary();
    }, [company]);

    // ##############################
    // // // Chart variables
    // #############################

    // impressionsChart and clicksChart options
    let chart1_2_options = {
      maintainAspectRatio: true,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        callbacks: {
            label: (tooltipItem, data) => {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel || '';

                const dateRange = fortnights[tooltipItem.index] || '';
                return `${label}: ${value} (${dateRange})`;
            },
        },
      },
      responsive: true,
      scales: {
        yAxes: {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
        xAxes: {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
            callback: (value, index, values) => {
                return value - 11; // Count down to -10 as leftmost point
            },
          },
        },
      },
    };

    let impressionsChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
        redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
        redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

        let greenGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        greenGradientStroke.addColorStop(1, "rgba(76,175,80,0.2)"); // Green
        greenGradientStroke.addColorStop(0.4, "rgba(76,175,80,0.0)");
        greenGradientStroke.addColorStop(0, "rgba(76,175,80,0)");

        let blueGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        blueGradientStroke.addColorStop(1, "rgba(33,150,243,0.2)"); // Blue
        blueGradientStroke.addColorStop(0.4, "rgba(33,150,243,0.0)");
        blueGradientStroke.addColorStop(0, "rgba(33,150,243,0)");

        return {
          labels: fortnights,
          datasets: [
            {
              label: "🇪🇺",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.EU?.impressions || [],
            },
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.US?.impressions || [],
            },
            {
              label: "🇬🇧",
              fill: true,
              backgroundColor: blueGradientStroke,
              borderColor: "#2196F3", // Blue
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#2196F3", // Blue
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#2196F3", // Blue
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.UK?.impressions || [],
            },
          ],
        };
      },
      options: chart1_2_options,
    };

    // #########################################
    // // // used inside src/views/Dashboard.js
    // #########################################
    let clicksChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
        redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
        redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

        let greenGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        greenGradientStroke.addColorStop(1, "rgba(76,175,80,0.2)"); // Green
        greenGradientStroke.addColorStop(0.4, "rgba(76,175,80,0.0)");
        greenGradientStroke.addColorStop(0, "rgba(76,175,80,0)");

        let blueGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        blueGradientStroke.addColorStop(1, "rgba(33,150,243,0.2)"); // Blue
        blueGradientStroke.addColorStop(0.4, "rgba(33,150,243,0.0)");
        blueGradientStroke.addColorStop(0, "rgba(33,150,243,0)");

        return {
          labels: fortnights,
          datasets: [
            {
              label: "🇪🇺",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.EU?.clicks || [],
            },
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.US?.clicks || [],
            },
            {
              label: "🇬🇧",
              fill: true,
              backgroundColor: blueGradientStroke,
              borderColor: "#2196F3", // Blue
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#2196F3", // Blue
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#2196F3", // Blue
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.UK?.clicks || [],
            },
          ],
        };
      },
      options: chart1_2_options,
    };

    // #########################################
    // // // used inside src/views/Dashboard.js
    // #########################################
    let activeCampaignsChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
        gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
        gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

        return {
          labels: summaryData && summaryData.campaign_count && summaryData.campaign_count[bigChartData] ? Object.keys(summaryData.campaign_count[bigChartData]) : [],
          datasets: [
            {
              label: "Prior",
              fill: true,
              backgroundColor: gradientStroke,
              hoverBackgroundColor: gradientStroke,
              borderColor: "#674c62",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: summaryData && summaryData.campaign_count && summaryData.campaign_count["past_" + bigChartData] ? Object.values(summaryData.campaign_count["past_" + bigChartData]) : [],
              hidden: true,
            },
            {
              label: "Current",
              fill: true,
              backgroundColor: gradientStroke,
              hoverBackgroundColor: gradientStroke,
              borderColor: "#d048b6",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: summaryData && summaryData.campaign_count && summaryData.campaign_count[bigChartData] ? Object.values(summaryData.campaign_count[bigChartData]) : [],
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
        responsive: true,
        scales: {
          yAxes: {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              // suggestedMin: 60,
              // suggestedMax: 120,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
        },
      },
    };

    // #########################################
    // // // used inside src/views/Dashboard.js
    // #########################################
    const purchasesChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
        redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
        redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

        let greenGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        greenGradientStroke.addColorStop(1, "rgba(76,175,80,0.2)"); // Green
        greenGradientStroke.addColorStop(0.4, "rgba(76,175,80,0.0)");
        greenGradientStroke.addColorStop(0, "rgba(76,175,80,0)");

        let blueGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        blueGradientStroke.addColorStop(1, "rgba(33,150,243,0.2)"); // Blue
        blueGradientStroke.addColorStop(0.4, "rgba(33,150,243,0.0)");
        blueGradientStroke.addColorStop(0, "rgba(33,150,243,0)");


        return {
          labels: fortnights,
          datasets: [
            {
              label: "🇪🇺",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.EU?.purchases || [],
            },
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.US?.purchases || [],
            },
            {
              label: "🇬🇧",
              fill: true,
              backgroundColor: blueGradientStroke,
              borderColor: "#2196F3", // Blue
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#2196F3", // Blue
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#2196F3", // Blue
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.UK?.purchases || [],
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: false,
        },

        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
            callbacks: {
                label: (tooltipItem, data) => {
                    const label = data.datasets[tooltipItem.datasetIndex].label || '';
                    const value = tooltipItem.yLabel || '';

                    const dateRange = fortnights[tooltipItem.index] || '';
                    return `${label}: ${value} (${dateRange})`;
                },
            },
        },
        responsive: true,
        scales: {
          yAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
                callback: (value, index, values) => {
                    if (value === 0) return "10"; //right most point
                    return value - 11; // Count down to -10 as leftmost point
                },
            },
          },
        },
      },
    };

    const spendChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
        redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
        redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

        let greenGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        greenGradientStroke.addColorStop(1, "rgba(76,175,80,0.2)"); // Green
        greenGradientStroke.addColorStop(0.4, "rgba(76,175,80,0.0)");
        greenGradientStroke.addColorStop(0, "rgba(76,175,80,0)");

        let blueGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        blueGradientStroke.addColorStop(1, "rgba(33,150,243,0.2)"); // Blue
        blueGradientStroke.addColorStop(0.4, "rgba(33,150,243,0.0)");
        blueGradientStroke.addColorStop(0, "rgba(33,150,243,0)");


        return {
          labels: fortnights,
          datasets: [
            {
              label: "🇪🇺",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.EU?.cost || [],
            },
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.US?.cost || [],
            },
            {
              label: "🇬🇧",
              fill: true,
              backgroundColor: blueGradientStroke,
              borderColor: "#2196F3", // Blue
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#2196F3", // Blue
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#2196F3", // Blue
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.UK?.cost || [],
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: false,
        },

        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
            callbacks: {
                label: (tooltipItem, data) => {
                    const label = data.datasets[tooltipItem.datasetIndex].label || '';
                    const value = tooltipItem.yLabel || '';

                    const dateRange = fortnights[tooltipItem.index] || '';
                    return `${label}: ${value} (${dateRange})`;
                },
            },
        },
        responsive: true,
        scales: {
          yAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
                callback: (value, index, values) => {
                    if (value === 0) return "10"; //right most point
                    return value - 11; // Count down to -10 as leftmost point
                },
            },
          },
        },
      },
    };

    const salesChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
        redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
        redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

        let greenGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        greenGradientStroke.addColorStop(1, "rgba(76,175,80,0.2)"); // Green
        greenGradientStroke.addColorStop(0.4, "rgba(76,175,80,0.0)");
        greenGradientStroke.addColorStop(0, "rgba(76,175,80,0)");

        let blueGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        blueGradientStroke.addColorStop(1, "rgba(33,150,243,0.2)"); // Blue
        blueGradientStroke.addColorStop(0.4, "rgba(33,150,243,0.0)");
        blueGradientStroke.addColorStop(0, "rgba(33,150,243,0)");


        return {
          labels: fortnights,
          datasets: [
            {
              label: "🇪🇺",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.EU?.sales || [],
            },
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.US?.sales || [],
            },
            {
              label: "🇬🇧",
              fill: true,
              backgroundColor: blueGradientStroke,
              borderColor: "#2196F3", // Blue
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#2196F3", // Blue
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#2196F3", // Blue
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.UK?.sales || [],
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: false,
        },

        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
            callbacks: {
                label: (tooltipItem, data) => {
                    const label = data.datasets[tooltipItem.datasetIndex].label || '';
                    const value = tooltipItem.yLabel || '';

                    const dateRange = fortnights[tooltipItem.index] || '';
                    return `${label}: ${value} (${dateRange})`;
                },
            },
        },
        responsive: true,
        scales: {
          yAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
                callback: (value, index, values) => {
                    if (value === 0) return "10"; //right most point
                    return value - 11; // Count down to -10 as leftmost point
                },
            },
          },
        },
      },
    };

    // Array to hold the true (uncapped) values
    const trueValues = {
      EU: [],
      US: [],
      UK: []
    };

    const acosChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
        redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
        redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

        let greenGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        greenGradientStroke.addColorStop(1, "rgba(76,175,80,0.2)"); // Green
        greenGradientStroke.addColorStop(0.4, "rgba(76,175,80,0.0)");
        greenGradientStroke.addColorStop(0, "rgba(76,175,80,0)");

        let blueGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        blueGradientStroke.addColorStop(1, "rgba(33,150,243,0.2)"); // Blue
        blueGradientStroke.addColorStop(0.4, "rgba(33,150,243,0.0)");
        blueGradientStroke.addColorStop(0, "rgba(33,150,243,0)");

        return {
          labels: fortnights,
          datasets: [
            {
              label: "🇪🇺",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: function (context) {
                const index = context.dataIndex;
                const cappedValue = context.dataset.data[index];
                const trueValue = trueValues.EU[index];
                return parseFloat(cappedValue) === 100 ? "#FFFF00" : "#FF5722"; // Red if capped
              },
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.EU?.cost?.map((cost, index) => {
                const trueValue = (cost / (summaryData?.data?.[bigChartData]?.EU?.sales?.[index] || 1)) * 100;
                const cappedValue = Math.min(trueValue, 100).toFixed(2);
                trueValues.EU.push(trueValue.toFixed(2));
                return cappedValue;
              }) || [],
            },
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: function (context) {
                const index = context.dataIndex;
                const cappedValue = context.dataset.data[index];
                const trueValue = trueValues.US[index];
                return parseFloat(cappedValue) === 100 ? "#FFFF00" : "#4CAF50"; // Red if capped
              },
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.US?.cost?.map((cost, index) => {
                const trueValue = (cost / (summaryData?.data?.[bigChartData]?.US?.sales?.[index] || 1)) * 100;
                const cappedValue = Math.min(trueValue, 100).toFixed(2);
                trueValues.US.push(trueValue.toFixed(2));
                return cappedValue;
              }) || [],
            },
            {
              label: "🇬🇧",
              fill: true,
              backgroundColor: blueGradientStroke,
              borderColor: "#2196F3", // Blue
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: function (context) {
                const index = context.dataIndex;
                const cappedValue = context.dataset.data[index];
                const trueValue = trueValues.UK[index];
                return parseFloat(cappedValue) === 100 ? "#FFFF00" : "#2196F3"; // Red if capped
              },
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#2196F3", // Blue
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.UK?.cost?.map((cost, index) => {
                const trueValue = (cost / (summaryData?.data?.[bigChartData]?.UK?.sales?.[index] || 1)) * 100;
                const cappedValue = Math.min(trueValue, 100).toFixed(2);
                trueValues.UK.push(trueValue.toFixed(2));
                return cappedValue;
              }) || [],
            },
            {
              label: "Profitability",
              fill: false,
              borderColor: "#a9b665", // Grey
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 0,
              data: [25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25],
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
        // plugins: {
        //     tooltip: {
        //         callbacks: {
        //             footer: (tooltipItem, data) => {
        //               const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
        //               const index = tooltipItem.index;
        //               const region = datasetLabel.includes("EU") ? "EU" : datasetLabel.includes("US") ? "US" : "UK";
        //               const cappedValue = tooltipItem.yLabel || '';
        //               const trueValue = trueValues[region][index];

        //               if (parseFloat(trueValue) > parseFloat(cappedValue)) {
        //                 return `true value: ${trueValue}`; // Show true value if capped
        //               }
        //             },
        //         },
        //     },
        // },
        tooltip: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
          callbacks: {
            label: (tooltipItem, data) => {
              const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
              const index = tooltipItem.index;
              const region = datasetLabel.includes("EU") ? "EU" : datasetLabel.includes("US") ? "US" : "UK";
              const cappedValue = tooltipItem.yLabel || '';
              const trueValue = trueValues[region][index];
              const dateRange = fortnights[index] || '';

              if (parseFloat(trueValue) > parseFloat(cappedValue)) {
                return `${datasetLabel}: ${trueValue} (${dateRange})`; // Show true value if capped
              } else {
                return `${datasetLabel}: ${cappedValue} (${dateRange})`;
              }
            },
            // footer: (tooltipItem, data) => {
            //   const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
            //   const index = tooltipItem.index;
            //   const region = datasetLabel.includes("EU") ? "EU" : datasetLabel.includes("US") ? "US" : "UK";
            //   const cappedValue = tooltipItem.yLabel || '';
            //   const trueValue = trueValues[region][index];

            //   if (parseFloat(trueValue) > parseFloat(cappedValue)) {
            //     return `true value: ${trueValue}`; // Show true value if capped
            //   }
            // },
          },
        },
        responsive: true,
        scales: {
          yAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
              callback: (value, index, values) => {
                if (value === 0) return "10"; //right most point
                return value - 11; // Count down to -10 as leftmost point
              },
            },
          },
        },
      },
    };

    const combinedChartUK = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let createGradient = (color) => {
          let gradient = ctx.createLinearGradient(0, 230, 0, 50);
          gradient.addColorStop(1, `${color}0.2)`);
          gradient.addColorStop(0.4, `${color}0.0)`);
          gradient.addColorStop(0, `${color}0)`);
          return gradient;
        };

        let redGradientStroke = createGradient("rgba(255,87,34,");
        let greenGradientStroke = createGradient("rgba(76,175,80,");
        let blueGradientStroke = createGradient("rgba(33,150,243,");

        return {
          labels: fortnights,
          datasets: [
            {
              label: "Revenue",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.UK?.sales || [],
            },
            {
              label: "Spend",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.UK?.cost || [],
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: true,
          onClick: (e, legendItem) => {
            const index = legendItem.datasetIndex;
            const ci = e.chart;
            const meta = ci.getDatasetMeta(index);
            const hidden = meta.hidden === null ? false : meta.hidden;

            // Hide all datasets with the same label except the clicked one
            ci.data.datasets.forEach((dataset, i) => {
              if (dataset.label === legendItem.text || dataset.label.startsWith(legendItem.text)) {
                ci.getDatasetMeta(i).hidden = !hidden;
              }
            });

            ci.update();
          },
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.datasets[tooltipItem.datasetIndex].label || "";
              const value = tooltipItem.yLabel || "";
              const dateRange = fortnights[tooltipItem.index] || "";
              return `${label}: ${value} (${dateRange})`;
            },
          },
        },
        responsive: true,
        scales: {
          yAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
              callback: (value, index, values) => {
                if (value === 0) return "10"; //right most point
                return value - 11; // Count down to -10 as leftmost point
              },
            },
          },
        },
      },
    };

    const combinedChartUS = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let createGradient = (color) => {
          let gradient = ctx.createLinearGradient(0, 230, 0, 50);
          gradient.addColorStop(1, `${color}0.2)`);
          gradient.addColorStop(0.4, `${color}0.0)`);
          gradient.addColorStop(0, `${color}0)`);
          return gradient;
        };

        let redGradientStroke = createGradient("rgba(255,87,34,");
        let greenGradientStroke = createGradient("rgba(76,175,80,");
        let blueGradientStroke = createGradient("rgba(33,150,243,");

        return {
          labels: fortnights,
          datasets: [
            {
              label: "Revenue",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.US?.sales || [],
            },
            {
              label: "Spend",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.US?.cost || [],
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: true,
          onClick: (e, legendItem) => {
            const index = legendItem.datasetIndex;
            const ci = e.chart;
            const meta = ci.getDatasetMeta(index);
            const hidden = meta.hidden === null ? false : meta.hidden;

            // Hide all datasets with the same label except the clicked one
            ci.data.datasets.forEach((dataset, i) => {
              if (dataset.label === legendItem.text || dataset.label.startsWith(legendItem.text)) {
                ci.getDatasetMeta(i).hidden = !hidden;
              }
            });

            ci.update();
          },
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.datasets[tooltipItem.datasetIndex].label || "";
              const value = tooltipItem.yLabel || "";
              const dateRange = fortnights[tooltipItem.index] || "";
              return `${label}: ${value} (${dateRange})`;
            },
          },
        },
        responsive: true,
        scales: {
          yAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
              callback: (value, index, values) => {
                if (value === 0) return "10"; //right most point
                return value - 11; // Count down to -10 as leftmost point
              },
            },
          },
        },
      },
    };

    const combinedChartEU = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let createGradient = (color) => {
          let gradient = ctx.createLinearGradient(0, 230, 0, 50);
          gradient.addColorStop(1, `${color}0.2)`);
          gradient.addColorStop(0.4, `${color}0.0)`);
          gradient.addColorStop(0, `${color}0)`);
          return gradient;
        };

        let redGradientStroke = createGradient("rgba(255,87,34,");
        let greenGradientStroke = createGradient("rgba(76,175,80,");
        let blueGradientStroke = createGradient("rgba(33,150,243,");

        return {
          labels: fortnights,
          datasets: [
            {
              label: "Revenue",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.EU?.sales || [],
            },
            {
              label: "Spend",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: summaryData?.data?.[bigChartData]?.EU?.cost || [],
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: true,
          onClick: (e, legendItem) => {
            const index = legendItem.datasetIndex;
            const ci = e.chart;
            const meta = ci.getDatasetMeta(index);
            const hidden = meta.hidden === null ? false : meta.hidden;

            // Hide all datasets with the same label except the clicked one
            ci.data.datasets.forEach((dataset, i) => {
              if (dataset.label === legendItem.text || dataset.label.startsWith(legendItem.text)) {
                ci.getDatasetMeta(i).hidden = !hidden;
              }
            });

            ci.update();
          },
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.datasets[tooltipItem.datasetIndex].label || "";
              const value = tooltipItem.yLabel || "";
              const dateRange = fortnights[tooltipItem.index] || "";
              return `${label}: ${value} (${dateRange})`;
            },
          },
        },
        responsive: true,
        scales: {
          yAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
              callback: (value, index, values) => {
                if (value === 0) return "10"; //right most point
                return value - 11; // Count down to -10 as leftmost point
              },
            },
          },
        },
      },
    };
    
    const costPerChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
        redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
        redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

        let greenGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        greenGradientStroke.addColorStop(1, "rgba(76,175,80,0.2)"); // Green
        greenGradientStroke.addColorStop(0.4, "rgba(76,175,80,0.0)");
        greenGradientStroke.addColorStop(0, "rgba(76,175,80,0)");

        let blueGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        blueGradientStroke.addColorStop(1, "rgba(33,150,243,0.2)"); // Blue
        blueGradientStroke.addColorStop(0.4, "rgba(33,150,243,0.0)");
        blueGradientStroke.addColorStop(0, "rgba(33,150,243,0)");


        return {
          labels: fortnights,
          datasets: [
            {
              label: "🇪🇺",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: cpcToggle
                ? summaryData?.data?.[bigChartData]?.EU?.cost?.map((cost, index) => ((cost / (summaryData?.data?.[bigChartData]?.EU?.clicks?.[index] || cost))).toFixed(2)) || []
                : summaryData?.data?.[bigChartData]?.EU?.cost?.map((cost, index) => ((cost / (summaryData?.data?.[bigChartData]?.EU?.purchases?.[index] || cost))).toFixed(2)) || [],
            },
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: cpcToggle
                ? summaryData?.data?.[bigChartData]?.US?.cost?.map((cost, index) => ((cost / (summaryData?.data?.[bigChartData]?.US?.clicks?.[index] || cost))).toFixed(2)) || []
                : summaryData?.data?.[bigChartData]?.US?.cost?.map((cost, index) => ((cost / (summaryData?.data?.[bigChartData]?.US?.purchases?.[index] || cost))).toFixed(2)) || [],
            },
            {
              label: "🇬🇧",
              fill: true,
              backgroundColor: blueGradientStroke,
              borderColor: "#2196F3", // Blue
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#2196F3", // Blue
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#2196F3", // Blue
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: cpcToggle
                ? summaryData?.data?.[bigChartData]?.UK?.cost?.map((cost, index) => ((cost / (summaryData?.data?.[bigChartData]?.UK?.clicks?.[index] || cost))).toFixed(2)) || []
                : summaryData?.data?.[bigChartData]?.UK?.cost?.map((cost, index) => ((cost / (summaryData?.data?.[bigChartData]?.UK?.purchases?.[index] || cost))).toFixed(2)) || [],
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: false,
        },

        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
            callbacks: {
                label: (tooltipItem, data) => {
                    const label = data.datasets[tooltipItem.datasetIndex].label || '';
                    const value = tooltipItem.yLabel || '';

                    const dateRange = fortnights[tooltipItem.index] || '';
                    return `${label}: ${value} (${dateRange})`;
                },
            },
        },
        responsive: true,
        scales: {
          yAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
                callback: (value, index, values) => {
                    if (value === 0) return "10"; //right most point
                    return value - 11; // Count down to -10 as leftmost point
                },
            },
          },
        },
      },
    };

    const convChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
        redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
        redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

        let greenGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        greenGradientStroke.addColorStop(1, "rgba(76,175,80,0.2)"); // Green
        greenGradientStroke.addColorStop(0.4, "rgba(76,175,80,0.0)");
        greenGradientStroke.addColorStop(0, "rgba(76,175,80,0)");

        let blueGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        blueGradientStroke.addColorStop(1, "rgba(33,150,243,0.2)"); // Blue
        blueGradientStroke.addColorStop(0.4, "rgba(33,150,243,0.0)");
        blueGradientStroke.addColorStop(0, "rgba(33,150,243,0)");


        return {
          labels: fortnights,
          datasets: [
            {
              label: "🇪🇺",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: convToggle
                ? summaryData?.data?.[bigChartData]?.EU?.clicks?.map((clicks, index) => ((clicks / (summaryData?.data?.[bigChartData]?.EU?.impressions?.[index] || 0)) * 100).toFixed(2)) || []
                : summaryData?.data?.[bigChartData]?.EU?.purchases?.map((purchases, index) => ((purchases / (summaryData?.data?.[bigChartData]?.EU?.clicks?.[index] || 0)) * 100).toFixed(2)) || [],
            },
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: convToggle
                ? summaryData?.data?.[bigChartData]?.US?.clicks?.map((clicks, index) => ((clicks / (summaryData?.data?.[bigChartData]?.US?.impressions?.[index] || 0)) * 100).toFixed(2)) || []
                : summaryData?.data?.[bigChartData]?.US?.purchases?.map((purchases, index) => ((purchases / (summaryData?.data?.[bigChartData]?.US?.clicks?.[index] || 0)) * 100).toFixed(2)) || [],
            },
            {
              label: "🇬🇧",
              fill: true,
              backgroundColor: blueGradientStroke,
              borderColor: "#2196F3", // Blue
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#2196F3", // Blue
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#2196F3", // Blue
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: convToggle
                ? summaryData?.data?.[bigChartData]?.UK?.clicks?.map((clicks, index) => ((clicks / (summaryData?.data?.[bigChartData]?.UK?.impressions?.[index] || 0)) * 100).toFixed(2)) || []
                : summaryData?.data?.[bigChartData]?.UK?.purchases?.map((purchases, index) => ((purchases / (summaryData?.data?.[bigChartData]?.UK?.clicks?.[index] || 0)) * 100).toFixed(2)) || [],
            },
          ],
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: false,
        },

        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
            callbacks: {
                label: (tooltipItem, data) => {
                    const label = data.datasets[tooltipItem.datasetIndex].label || '';
                    const value = tooltipItem.yLabel || '';

                    const dateRange = fortnights[tooltipItem.index] || '';
                    return `${label}: ${value} (${dateRange})`;
                },
            },
        },
        responsive: true,
        scales: {
          yAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 50,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
          xAxes: {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
                callback: (value, index, values) => {
                    if (value === 0) return "10"; //right most point
                    return value - 11; // Count down to -10 as leftmost point
                },
            },
          },
        },
      },
    };
    
  return (
    <Card className="protected-container">
      <h2 style={{ textAlign: 'center' }}>Advertising Performance Overview</h2>
      <ButtonGroup variation="primary" justifyContent="right">
        <Button onClick={() => setbigChartData("total")} colorTheme={bigChartData === "total" ? "info" : "warning"}>All</Button>
        <Button onClick={() => setbigChartData("sp")} colorTheme={bigChartData === "sp" ? "info" : "warning"}>Sponsored Products</Button>
        <Button onClick={() => setbigChartData("sb")} colorTheme={bigChartData === "sb" ? "info" : "warning"}>Sponsored Brands</Button>
        <Button onClick={() => setbigChartData("sd")} colorTheme={bigChartData === "sd" ? "info" : "warning"}>Sponsored Display</Button>
      </ButtonGroup>
        <div className="content">
          <Grid
            columnGap="0.5rem"
            rowGap="0.5rem"
            templateColumns="1fr 1fr"
            templateRows="1fr 1fr"
          >
              <Col xs="12">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h3">Impressions</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={impressionsChart.data}
                        options={impressionsChart.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h3">Clicks</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={clicksChart.data}
                        options={clicksChart.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h3">Purchases</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={purchasesChart.data}
                        options={purchasesChart.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h3">ACOS</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={acosChart.data}
                        options={acosChart.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
          </Grid>
        <CardTitle tag="h3">
          <i className="tim-icons icon-send text-success" /> Revenue and Spend
        </CardTitle>
          <Grid
            columnGap="0.5rem"
            rowGap="0.5rem"
            templateColumns="1fr 1fr 1fr"
            templateRows="1fr"
          >
              <Col lg="4">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h3">
                            🇪🇺
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={combinedChartEU.data}
                        options={combinedChartEU.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h3">
                            🇺🇸
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={combinedChartUS.data}
                        options={combinedChartUS.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h3">
                            🇬🇧
                        </CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={combinedChartUK.data}
                        options={combinedChartUK.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Grid>
          <Grid
            columnGap="0.5rem"
            rowGap="0.5rem"
            templateColumns="1fr 1fr"
            templateRows="1fr"
          >
              <Col xs="12">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h3">
                            {convToggle ? "Click Through Rate" : "Conversion Rate"}
                        </CardTitle>
                      </Col>
                      <Col className="text-right" sm="6">
                        <SwitchField
                            trackColor="#f46f22"
                            trackCheckedColor="#f46f22"
                            label={convToggle ? "Toggle to conv" : "Toggle to CTR"}
                            checked={convToggle}
                            onChange={(e) => setConvToggle(e.target.checked)}
                        />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={convChart.data}
                        options={convChart.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h3">
                            {cpcToggle ? "Cost Per Click" : "Cost Per Purchase"}
                        </CardTitle>
                      </Col>
                      <Col className="text-right" sm="6">
                        <SwitchField
                            trackColor="#f46f22"
                            trackCheckedColor="#f46f22"
                            label={cpcToggle ? "Toggle to CPP" : "Toggle to CPC"}
                            checked={cpcToggle}
                            onChange={(e) => setCpcToggle(e.target.checked)}
                        />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={costPerChart.data}
                        options={costPerChart.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
          </Grid>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h2 className="card-category"></h2>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-send text-success" /> No. Active Campaigns
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={activeCampaignsChart.data}
                    options={activeCampaignsChart.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
    </Card>
    );
}

export { Dashboard };
