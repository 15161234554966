import { listTodos } from '../graphql/queries'
import { withAuthenticator, Button, Text, Flex, Heading, Accordion, TextAreaField, SelectField, Divider } from "@aws-amplify/ui-react";
import { React, useCallback, useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { SidePopout } from './SidePopout';
import './css/Notes.css';

const ViewNotes = ({ company }) => {
    const [ notes, setNotes ] = useState([])
    const client = generateClient({ authMode: 'userPool' });

    const fetchNotes = useCallback(async () => {
        const result = await client.graphql({ query: listTodos });
        const sortedNotes = result.data.listTodos.items.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setNotes(sortedNotes);
    }, [setNotes]);

    useEffect(() => {
        fetchNotes();
    }, [fetchNotes]);

    return (
        <div style={{backgroundColor: '#bfbfbf', marginLeft: "300px", marginRight: "300px"}}>
        <Flex direction={"column"} marginLeft="100px" marginRight="100px">
            <Flex justifyContent={'space-between'}>
                <Heading level={1} marginTop="50px" marginBottom="50px">Campaign Action Items</Heading>
            </Flex>
            {notes
                .map(note => (
                <Flex key={note.id} direction="column" alignItems={'flex-start'} marginTop="1rem">
                    <Divider
                        orientation="horizontal"
                    />
                    <Text fontWeight={650} marginBottom="0.25rem" textAlign="left">{note.campaign}</Text>
                    <Text fontSize="small" fontWeight={650}>Reporting Period: {note.date}</Text>
                    <Text>{note.text}</Text>
                    <Flex justifyContent="space-between" width="100%" alignItems={'flex-start'}>
                        <Text fontSize="small" color="gray.500" marginLeft="auto">
                            {note.author} - {note.createdAt}
                        </Text>
                    </Flex>
                </Flex>
            ))}
        </Flex>
        </div>
    );
}

export { ViewNotes };
