import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import { Line, Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Grid, Button, ButtonGroup, Heading, SwitchField, SelectField, View } from '@aws-amplify/ui-react';
import { RangeDatePicker } from "@y0c/react-datepicker";

import { fetchOrganic } from './Dev';

import './css/dashboard.css';


const Organic = ({ company }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState([]);
    const [UK, setUK] = useState([]);
    const [EU, setEU] = useState([]);
    const [granularity, setGranularity] = useState("day");
    const [showB2B, setShowB2B] = useState(false);

    const handleToggle = (event) => {
        setShowB2B(event.target.checked);
    };

    const handleDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!startDate || !endDate) {
            alert("Please select both a start and end date.");
            return;
        }

        const formattedStart = startDate.toISOString().slice(0, 10);
        const formattedEnd = endDate.toISOString().slice(0, 10);

        try {
            const newData = await fetchOrganic("NA", formattedStart, formattedEnd, granularity);
            setData(newData || []);
            const newEU = await fetchOrganic("EU", formattedStart, formattedEnd, granularity);
            setEU(newEU || []);
            const newUK = await fetchOrganic("UK", formattedStart, formattedEnd, granularity);
            setUK(newUK || []);
        } catch (error) {
            console.error('Error fetching organic data:', error);
        }
    };

    const chart_options = {
      maintainAspectRatio: true,
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [{
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
          },
        }],
        xAxes: [{
          gridLines: {
            drawBorder: false,
          },
        }],
      },
      layout: {
        padding: {
          top: 20,
          bottom: 20,
        }
      },
    };
    function gradients(ctx) {
        let redGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        redGradientStroke.addColorStop(1, "rgba(255,87,34,0.2)"); // Red
        redGradientStroke.addColorStop(0.4, "rgba(255,87,34,0.0)");
        redGradientStroke.addColorStop(0, "rgba(255,87,34,0)");

        let greenGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        greenGradientStroke.addColorStop(1, "rgba(76,175,80,0.2)"); // Green
        greenGradientStroke.addColorStop(0.4, "rgba(76,175,80,0.0)");
        greenGradientStroke.addColorStop(0, "rgba(76,175,80,0)");

        let blueGradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        blueGradientStroke.addColorStop(1, "rgba(33,150,243,0.2)"); // Blue
        blueGradientStroke.addColorStop(0.4, "rgba(33,150,243,0.0)");
        blueGradientStroke.addColorStop(0, "rgba(33,150,243,0)");

        return [redGradientStroke, greenGradientStroke, blueGradientStroke];
    }

    let salesChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        if (!data || data.length === 0) {
            return {
                labels: [], // No labels
                datasets: [
                  {
                    label: "No Data",
                    fill: true,
                    backgroundColor: "rgba(0,0,0,0)", // Transparent background
                    borderColor: "rgba(0,0,0,0)", // Transparent border
                    data: [], // No data points
                  },
                ],
            };
        };
    
        let [redGradientStroke, greenGradientStroke, blueGradientStroke] = gradients(ctx);

        let dates = data.map(item => item.date);
        let sales = data.map(item => item.sales);

        return {
          labels: dates,
          datasets: [
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: sales
            },
          ],
        };
      },
      options: chart_options,
    };

    let salesB2BChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        if (!data || data.length === 0) {
            return {
                labels: [], // No labels
                datasets: [
                  {
                    label: "No Data",
                    fill: true,
                    backgroundColor: "rgba(0,0,0,0)", // Transparent background
                    borderColor: "rgba(0,0,0,0)", // Transparent border
                    data: [], // No data points
                  },
                ],
            };
        };
    
        let [redGradientStroke, greenGradientStroke, blueGradientStroke] = gradients(ctx);

        let dates = data.map(item => item.date);
        let graph_data = data.map(item => item.salesB2B);
        let eu_data = EU.map(item => item.salesB2B);
        let uk_data = UK.map(item => item.salesB2B);

        return {
          labels: dates,
          datasets: [
            {
              label: "🇪🇺",
              fill: true,
              backgroundColor: redGradientStroke,
              borderColor: "#FF5722", // Red
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#FF5722", // Red
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#FF5722", // Red
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: eu_data,
            },
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: graph_data
            },
            {
              label: "🇬🇧",
              fill: true,
              backgroundColor: blueGradientStroke,
              borderColor: "#2196F3", // Blue
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#2196F3", // Blue
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#2196F3", // Blue
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: uk_data,
            },
          ],
        };
      },
      options: chart_options,
    };

    let unitsChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        if (!data || data.length === 0) {
            return {
                labels: [], // No labels
                datasets: [
                  {
                    label: "No Data",
                    fill: true,
                    backgroundColor: "rgba(0,0,0,0)", // Transparent background
                    borderColor: "rgba(0,0,0,0)", // Transparent border
                    data: [], // No data points
                  },
                ],
            };
        };
    
        let [redGradientStroke, greenGradientStroke, blueGradientStroke] = gradients(ctx);

        let dates = data.map(item => item.date);
        let graph_data = data.map(item => item.unitsOrdered);

        return {
          labels: dates,
          datasets: [
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: graph_data
            },
          ],
        };
      },
      options: chart_options,
    };

    let unitsB2BChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        if (!data || data.length === 0) {
            return {
                labels: [], // No labels
                datasets: [
                  {
                    label: "No Data",
                    fill: true,
                    backgroundColor: "rgba(0,0,0,0)", // Transparent background
                    borderColor: "rgba(0,0,0,0)", // Transparent border
                    data: [], // No data points
                  },
                ],
            };
        };
    
        let [redGradientStroke, greenGradientStroke, blueGradientStroke] = gradients(ctx);

        let dates = data.map(item => item.date);
        let graph_data = data.map(item => item.unitsOrderedB2B);

        return {
          labels: dates,
          datasets: [
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: graph_data
            },
          ],
        };
      },
      options: chart_options,
    };

    let sessionsChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        if (!data || data.length === 0) {
            return {
                labels: [], // No labels
                datasets: [
                  {
                    label: "No Data",
                    fill: true,
                    backgroundColor: "rgba(0,0,0,0)", // Transparent background
                    borderColor: "rgba(0,0,0,0)", // Transparent border
                    data: [], // No data points
                  },
                ],
            };
        };
    
        let [redGradientStroke, greenGradientStroke, blueGradientStroke] = gradients(ctx);

        let dates = data.map(item => item.date);
        let graph_data = data.map(item => item.sessions);

        return {
          labels: dates,
          datasets: [
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: graph_data
            },
          ],
        };
      },
      options: chart_options,
    };

    let sessionsB2BChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        if (!data || data.length === 0) {
            return {
                labels: [], // No labels
                datasets: [
                  {
                    label: "No Data",
                    fill: true,
                    backgroundColor: "rgba(0,0,0,0)", // Transparent background
                    borderColor: "rgba(0,0,0,0)", // Transparent border
                    data: [], // No data points
                  },
                ],
            };
        };
    
        let [redGradientStroke, greenGradientStroke, blueGradientStroke] = gradients(ctx);

        let dates = data.map(item => item.date);
        let graph_data = data.map(item => item.sessionsB2B);

        return {
          labels: dates,
          datasets: [
            {
              label: "🇺🇸",
              fill: true,
              backgroundColor: greenGradientStroke,
              borderColor: "#4CAF50", // Green
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#4CAF50", // Green
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#4CAF50", // Green
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: graph_data
            },
          ],
        };
      },
      options: chart_options,
    };

    return (
        <div>
            <Grid
                templateColumns = "1fr 4fr"
                templateRows = "1fr"
                gap = "10rem"
            >
                <View style={{ display: 'flex', flexDirection: 'column', gap: '16px'}}>
                    <Heading color="#b9b5b2"> Select date range</Heading>
                    <RangeDatePicker
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleDateChange}
                        placeholderStart="Start Date"
                        placeholderEnd="End Date"
                    />
                    
                    <SelectField
                        label="Granularity"
                        value={granularity}
                        onChange={(e) => setGranularity(e.target.value)}
                    >
                        <option value="day">Daily</option>
                        <option value="week">Weekly</option>
                        <option value="month">Monthly</option>
                    </SelectField>

                    <Button
                        variation="primary"
                        colorTheme="warning"
                        onClick={handleSubmit}>Submit</Button>
                </View>
            
                <div>
                    <SwitchField
                        label="Standard | B2B"
                        labelPosition="top"
                        checked={showB2B}
                        onChange={handleToggle}
                    />
                    <Grid
                        templateColumns = "1fr"
                        templateRows = "1fr 1fr 1fr"
                        gap = "10rem"
                    >
                        {!showB2B ? (
                            <>
                                <div>
                                    <Heading color="#b9b5b2">Sales</Heading>
                                    <Line
                                        key="salesB2B"
                                        data={salesB2BChart.data}
                                        options={salesB2BChart.options}
                                    />
                                    </div>
                                    <div>
                                        <Heading color="#b9b5b2">Units Ordered</Heading>
                                    <Line
                                        key="unitsB2B"
                                        data={unitsB2BChart.data}
                                        options={unitsB2BChart.options}
                                    />
                                    </div>
                                    <div>
                                        <Heading color="#b9b5b2">Sessions</Heading>
                                    <Line
                                        key="sessionsB2B"
                                        data={sessionsB2BChart.data}
                                        options={sessionsB2BChart.options}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <Heading color="#b9b5b2">Sales</Heading>
                                    <Line
                                        key="sales"
                                        data={salesChart.data}
                                        options={salesChart.options}
                                    />
                                </div>
                                <div>
                                    <Heading color="#b9b5b2">Units Ordered</Heading>
                                    <Line
                                        key="units"
                                        data={unitsChart.data}
                                        options={unitsChart.options}
                                    />
                                </div>
                                <div>
                                    <Heading color="#b9b5b2">Sessions</Heading>
                                    <Line
                                        key="sessions"
                                        data={sessionsChart.data}
                                        options={sessionsChart.options}
                                    />
                                </div>
                            </>
                        )}
                    </Grid>
                </div>
            </Grid>
        </div>
    );
};

export { Organic };
